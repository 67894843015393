import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Button, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledButtonDropdown} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import {Link} from 'react-router-dom';
import {getInventorySources} from '../Inventory/InventoryFunctions.js';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {getFactors} from "../Common/Factors";

class KegsAdjust extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            // default search settings
            action_type: 1,
            inventory_source_id: 1,
            factor_id: null,
            // default search filter names
            inventory_source_print: "TNG",
            action_types: {0: "Write-Off (Keep Deposit)", 1: "Pickup (Return Deposit)"},
            factor_name: "All",
            notes: "",
            inventory_sources: [],
            factors: [],
            accounts: [],
            search_term: ""
        };
        this.updateField = this.updateField.bind(this);
    }

    componentDidMount() {
        document.title = "Record Kegs | BevOps";
        var self = this;

        getInventorySources(null, function (inventory_sources) {
            getFactors(function (factors) {
                self.setState({
                    inventory_sources: inventory_sources,
                    factors: factors,
                });
                self.updateAccounts();
            });
        });
    }

    updateAccounts() {
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/kegs/accounts')
            .then(function (response) {
                self.setState({
                    accounts: response.data,
                    loading: false

                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        orders: [],
                        loading: false
                    });
                }
            });
    }

    changeFactor = (factor_id) => {
        if (factor_id == null) {
            var factor_name = "All"
        } else {
            var factor_name = this.state.factors[factor_id].name
        }
        this.setState({
            factor_id: factor_id,
            factor_name: factor_name
        });
    };
    changeInventory = (inventory_source_id) => {
        if (inventory_source_id == null) {
            var inventory_source_print = "All"
        } else {
            var inventory_source_print = this.state.inventory_sources[inventory_source_id].name
        }
        this.setState({
            inventory_source_id: inventory_source_id,
            inventory_source_print: inventory_source_print
        });
    };
    changeAction = (code) => {
        this.setState({
            action_type: code,
        });
    };

    // interractions
    IncrementItem = (account_key, factor_id) => {
        var old_accounts = this.state.accounts;
        old_accounts[account_key].factors[factor_id].returns++;
        this.setState({accounts: old_accounts});
    };
    DecreaseItem = (account_key, factor_id) => {
        var old_accounts = this.state.accounts;
        old_accounts[account_key].factors[factor_id].returns--;
        this.setState({accounts: old_accounts});
    };
    ChangeItem = (account_key, factor_id, event) => {
        if (!isNaN(event.target.value)) {
            var old_accounts = this.state.accounts;
            old_accounts[account_key].factors[factor_id].returns = event.target.value;
            this.setState({accounts: old_accounts});
        }
    };
    handleFocus = function (event) {
        if (event.target.value == "0") {
            event.target.value = "";
        }
        event.target.select();
    };

    recordReturns = () => {
        var self = this;
        var data = {'accounts': this.state.accounts, 'code': this.state.action_type, 'inventory_source_id': this.state.inventory_source_id, 'notes': this.state.notes}
        axios.defaults.withCredentials = true;
        axios.post(API_ROOT + '/kegs/accounts', data)
            .then(function (response) {
                Swal("Changes Saved", "The report will now refresh", "success");
                self.setState({
                    loading: true,
                    notes: ""
                });
                self.updateAccounts();
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        orders: [],
                        loading: false
                    });
                }
            });
    }

    updateField = (event) => {
        console.log(event)
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    render() {
        var total_quantity = parseInt(0);

        return (
            <ContentWrapper>
                <div className="content-heading">
                    Record Keg Returns
                </div>
                <div className="mb-3 form-inline">
                    <UncontrolledButtonDropdown className="m-1">
                        <DropdownToggle caret color="primary" style={{width: 'auto'}}>
                            Size: {this.state.factor_name}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.changeFactor(null)}>All</DropdownItem>
                            <DropdownItem divider/>
                            {Object.keys(this.state.factors).map((factor_id) =>
                                <DropdownItem className={(this.state.factors[factor_id].hectilitre > 0 ? "" : "d-none")} key={factor_id}
                                              onClick={() => this.changeFactor(factor_id)}>{this.state.factors[factor_id].name}</DropdownItem>
                            )}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <input type="text" className="form-control" value={this.state.search_term} name="search_term" placeholder="Search by account name" onChange={this.updateField}/>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div className={(this.state.loading ? "invisible" : "")}>
                        <div className="card-header">
                            <div className="card-title">{this.state.accounts.length} Accounts with Kegs</div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className={(this.state.accounts.length > 0 && !this.state.loading ? "table table-hover" : "d-none")}>
                                    <thead>
                                    <tr>
                                        <th className="d-none d-xl-table-cell">License #</th>
                                        <th>Account</th>
                                        <th>Address</th>
                                        <th>City</th>
                                        <th>Size</th>
                                        <th className="text-right">Outstanding Kegs</th>
                                        <th className="text-right">Returned Kegs</th>
                                        <th className="text-right d-none d-sm-table-cell">New Balance</th>
                                    </tr>
                                    </thead>
                                    {this.state.accounts.map(function (account, account_key) {
                                        return (
                                            <tbody key={account_key}>
                                            {account.factors.map(function (factor, factor_key) {
                                                total_quantity = (parseInt(total_quantity) + parseInt(factor.returns));
                                                if (this.state.search_term == "" || account.name.toLowerCase().includes(this.state.search_term.toLowerCase())) {
                                                    return (
                                                        <tr key={factor_key}
                                                            className={((this.state.factor_id == null || this.state.factor_id == factor.factor_id) ? "" : "d-none")}>
                                                            <td className="d-none d-xl-table-cell">{account.license_num}</td>
                                                            <td><Link to={"../account/" + account.account_id}>{account.name}</Link></td>
                                                            <td>{account.address1}</td>
                                                            <td>{account.city}</td>
                                                            <td>{this.state.factors[factor.factor_id].name}</td>
                                                            <td className="text-right">{factor.balance}</td>
                                                            <td>
                                                                <div className="input-group justify-content-end">
                                                                    <div className="input-group-prepend d-none d-sm-block" style={{cursor: "pointer"}}
                                                                         onClick={this.DecreaseItem.bind(this, account_key, factor_key)}>
                                                                        <span className="input-group-text font-weight-bold" id="inputGroup-sizing-sm">-</span>
                                                                    </div>
                                                                    <input type="tel" className={(factor.returns > 0 ? "form-control text-bold" : "form-control")}
                                                                           value={factor.returns} onFocus={this.handleFocus}
                                                                           onChange={this.ChangeItem.bind(this, account_key, factor_key)}
                                                                           style={{maxWidth: '90px'}}></input>
                                                                    <div className="input-group-append d-none d-sm-block" style={{cursor: "pointer"}}
                                                                         onClick={this.IncrementItem.bind(this, account_key, factor_key)}>
                                                                        <span className="input-group-text font-weight-bold" id="inputGroup-sizing-sm">+</span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="text-right">{(factor.balance - factor.returns)}</td>
                                                        </tr>
                                                    )
                                                }
                                            }, this)}
                                            </tbody>
                                        )
                                    }, this)}
                                    <tfoot>
                                    <tr>
                                        <th colSpan="5" className="text-right">Total Returned:</th>
                                        <th className="text-right">{total_quantity}</th>
                                        <th colSpan="2"></th>
                                    </tr>
                                    <tr>
                                        <th colSpan="5"></th>
                                        <th colSpan="3">
                                            <div>
                                                <UncontrolledButtonDropdown className="mt-1">
                                                    <DropdownToggle color="secondary" caret style={{width: 'auto'}}>
                                                        Pickup By: <b>{this.state.inventory_source_print}</b>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem key="null" onClick={() => this.changeInventory(null)}>All</DropdownItem>
                                                        <DropdownItem divider/>
                                                        {Object.keys(this.state.inventory_sources).map(function (inventory_source_id, key) {
                                                            return (
                                                                <DropdownItem key={inventory_source_id}
                                                                              onClick={() => this.changeInventory(inventory_source_id, 1)}>{(inventory_source_id == 0 ? "--" : this.state.inventory_sources[inventory_source_id].name)}</DropdownItem>
                                                            )
                                                        }, this)}
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </div>
                                            <div>
                                                <UncontrolledButtonDropdown className="mt-1 mb-1">
                                                    <DropdownToggle color="secondary" caret style={{width: 'auto'}}>
                                                        Type: <b>{this.state.action_types[this.state.action_type]}</b>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {Object.keys(this.state.action_types).map(function (code) {
                                                            return (
                                                                <DropdownItem key={code} onClick={() => this.changeAction(code)}>{this.state.action_types[code]}</DropdownItem>
                                                            )
                                                        }, this)}
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </div>
                                            <div>
                                                <textarea className="form-control" placeholder="Notes (visible to customer)" name="notes"
                                                          onChange={this.updateField}>{this.state.notes}</textarea>
                                            </div>
                                            <div>
                                                <Button color="primary" onClick={this.recordReturns}>Record Returns</Button>
                                            </div>
                                        </th>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (KegsAdjust);
