import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Button, InputGroup, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledButtonDropdown} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import {Link} from 'react-router-dom';
import {getInventorySources} from '../Inventory/InventoryFunctions.js';
import {getProducts} from '../Products/ProductFunctions.js';
import {getStaff} from '../Staff/StaffFunctions.js';
import {getAccounts} from '../Accounts/AccountFunctions.js';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault("America/Toronto");
import {getOwners} from "../Owners/OwnerFunctions";


class InventoryTransactions extends React.Component {
    constructor(props) {
        super(props);

        this.updateDates = this.updateDates.bind(this);
        this.goToAccount = this.goToPickup.bind(this);

        this.state = {
            transactions: [],
            loading: true,
            // default search settings
            action_type: null,
            inventory_source_id: null,
            destination_id: null,
            product_id: null,
            staff_id: null,
            startDate: moment().subtract(29, 'days'),
            endDate: moment().endOf('day'),
            // default search filter names
            inventory_source_print: "All",
            destination_print: "All",
            product_name: "All",
            staff_name: "All",
            // data
            company: (localStorage.getItem('company') != null ? localStorage.getItem('company') : null),
            companies_name: (localStorage.getItem('company_name') != null ? localStorage.getItem('company_name') : "All"),
            companies: [],
            codes: {1: "New Product", 4: "Reconciliation", 2: "Damaged", 3: "Sample", 0: "Transfer"},
            action_types: {0: "Transfer", 1: "Addition", 2: "Removal"},
            inventory_sources: [],
            products: [],
            staff: [],
            accounts: [],
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment().endOf('day')],
                'Last 30 Days': [moment().subtract(29, 'days'), moment().endOf('day')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'All Time': [moment("01 Jun 2018"), moment().endOf('day')]
            }
        };
    }

    componentDidMount() {
        document.title = "Product Pickup Requests | BevOps";
        var self = this;
        getInventorySources(null, function (inventory_sources) {
            getProducts(function (products) {
                getStaff(function (staff) {
                    getAccounts(function (accounts) {
                        getOwners(function (owners) {
                            self.setState({
                                inventory_sources: inventory_sources,
                                products: products,
                                staff: staff,
                                accounts: accounts,
                                companies: owners
                            });
                            Object.keys(inventory_sources).map(function (destination_id, key) {
                                if (inventory_sources[destination_id].staff_id == localStorage.getItem('staff_id')) {
                                    self.setState({
                                        destination_id: destination_id,
                                        destination_print: self.state.inventory_sources[destination_id].name
                                    });
                                }
                            })
                            self.getTransactions();
                        });
                    });
                });
            });
        });
    }

    // on date range change
    updateDates(event, picker) {
        if (event.type == "apply") {
            this.setState({
                startDate: picker.startDate,
                endDate: picker.endDate
            }, () => {
                this.getTransactions()
            });
        }
    }

    changeProduct = (product_id) => {
        if (product_id == null) {
            var product_name = "All"
        } else {
            var product_name = this.state.products[product_id].name
        }
        this.setState({
            product_id: product_id,
            product_name: product_name
        }, () => {
            this.getTransactions()
        });
    };
    changeInventory = (inventory_source_id) => {
        if (inventory_source_id == null) {
            var inventory_source_print = "All"
        } else {
            var inventory_source_print = this.state.inventory_sources[inventory_source_id].name
        }
        this.setState({
            inventory_source_id: inventory_source_id,
            inventory_source_print: inventory_source_print
        }, () => {
            this.getTransactions()
        });
    };
    changeDestination = (destination_id) => {
        if (destination_id == null) {
            var destination_print = "All"
        } else {
            var destination_print = this.state.inventory_sources[destination_id].name
        }
        this.setState({
            destination_id: destination_id,
            destination_print: destination_print
        }, () => {
            this.getTransactions()
        });
    };
    changeCompany = (company_id) => {
        if (company_id == null) {
            var companies_name = "All"
        } else {
            var companies_name = this.state.companies[company_id].name
        }
        localStorage.setItem('company', company_id);
        localStorage.setItem('company_name', companies_name);
        this.setState({
            company: company_id,
            companies_name: companies_name
        }, () => {
            this.getTransactions()
        });
    }

    goToPickup = (pickup_id) => {
        console.log("/pickup/" + pickup_id);
        this.props.history.push("/pickup/" + pickup_id);
    };

    getTransactions() {
        this.setState({
            loading: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/inventory/transactions/?action_type=null&category=0&inventory_source_id=' + self.state.inventory_source_id + "&destination_id=" + self.state.destination_id + "&product_id=" + self.state.product_id + "&startDate=" + self.state.startDate + "&endDate=" + self.state.endDate + "&order_type=0&staff_id=" + self.state.staff_id + "&account_type=null&company_id=" + self.state.company + "&restrict=pickup")
            .then(function (response) {
                self.setState({
                    transactions: response.data,
                    loading: false

                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        orders: [],
                        loading: false
                    });
                }
            });
    }

    render() {
        // date range selector variables
        let start = this.state.startDate.format('MMM. D/YY');
        let end = this.state.endDate.format('MMM. D/YY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }
        var last_pickup_id = 0;
        var self = this;

        return (
            <ContentWrapper>
                <div className="content-heading">
                    Product Pickup Requests
                    <div className="ml-auto">
                        <Link to="pickup/create" className="btn btn-primary"><i className="fas fa-plus"></i> Create Pickup Request</Link>
                    </div>
                </div>
                <div className="mb-3 form-inline">
                    <DateRangePicker
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        maxDate={moment()}
                        ranges={this.state.ranges}
                        onEvent={this.updateDates}
                        autoUpdateInput={true}
                    >
                        <Button className="selected-date-range-btn mr-1" color="primary">
                            Period: <b>{label}</b> <span className="fas fa-caret-down fa-sm"></span>
                        </Button>
                    </DateRangePicker>
                    <InputGroup>
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color={(String(this.state.company) == "null" ? "primary" : "warning")} style={{fontSize: '13px', width: 'auto'}}>
                                Companies: <b>{this.state.companies_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeCompany(null)}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.companies).map((key, i) => {
                                        if (localStorage.getItem('companies') == "ALL" || localStorage.getItem('companies').indexOf(',' + key + ',') != -1) {
                                            return (<DropdownItem key={key} onClick={() => this.changeCompany(key)}>{this.state.companies[key].name}</DropdownItem>)
                                        }
                                    }
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                    <UncontrolledButtonDropdown className="m-1">
                        <DropdownToggle caret color="primary" style={{width: 'auto'}}>
                            Product: {this.state.product_name}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.changeProduct(null)}>All</DropdownItem>
                            <DropdownItem divider/>
                            {Object.keys(this.state.products).map((products_id) =>
                                <DropdownItem key={products_id} onClick={() => this.changeProduct(products_id)}>{this.state.products[products_id].name}</DropdownItem>
                            )}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <UncontrolledButtonDropdown className="m-1">
                        <DropdownToggle color="primary" caret style={{width: 'auto'}}>
                            Source: {this.state.inventory_source_print}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.changeInventory(null)}>All</DropdownItem>
                            <DropdownItem divider/>
                            {Object.keys(this.state.inventory_sources).map(function (inventory_source_id, key) {
                                if (self.state.inventory_sources[inventory_source_id].staff_id == null) {
                                    return (
                                        <DropdownItem key={inventory_source_id}
                                                      onClick={() => this.changeInventory(inventory_source_id, 1)}>{(inventory_source_id == 0 ? "--" : this.state.inventory_sources[inventory_source_id].name)}</DropdownItem>
                                    )
                                }
                            }, this)}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <UncontrolledButtonDropdown className="m-1">
                        <DropdownToggle color="primary" caret style={{width: 'auto'}}>
                            Requestor: {this.state.destination_print}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.changeDestination(null)}>All</DropdownItem>
                            <DropdownItem divider/>
                            {Object.keys(this.state.inventory_sources).map(function (destination_id, key) {
                                if (self.state.inventory_sources[destination_id].staff_id > 0) {
                                    return (
                                        <DropdownItem key={destination_id}
                                                      onClick={() => this.changeDestination(destination_id, 1)}>{(destination_id == 0 ? "--" : this.state.inventory_sources[destination_id].name)}</DropdownItem>
                                    )
                                }
                            }, this)}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div className={(this.state.loading ? "invisible" : "")}>
                        <div className="card-header">
                            <div className="card-title">Past Pickup Requests</div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className={(this.state.transactions.length > 0 && !this.state.loading ? "table table-hover" : "d-none")}>
                                    <thead>
                                    <tr className="thead-light">
                                        <th className="d-none d-xl-table-cell">ID</th>
                                        <th>Date</th>
                                        <th>Source</th>
                                        <th>Requestor</th>
                                        <th className="d-none d-sm-table-cell">Notes</th>
                                        <th>Product</th>
                                        <th className="text-right">Qty.</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.transactions.map(function (item, key) {
                                        var quantity = item.quant;
                                        var notes = "";
                                        var order_text = "";
                                        var url = "";
                                        if (quantity > 0) {
                                            quantity = "+" + quantity;
                                        }
                                        var d_ent = item.d_ent;
                                        if (typeof d_ent == "undefined") {
                                            d_ent = item.d_eff;
                                        }
                                        notes = item.notes;
                                        if (quantity < 0) {
                                            quantity = quantity * -1;
                                            var style = (last_pickup_id == item.pickup_id ? {borderTop: "0px", paddingTop: "0px"} : {});
                                            var clss = (last_pickup_id == item.pickup_id ? "invisible" : "");
                                            last_pickup_id = item.pickup_id;
                                            return (
                                                <tr key={key} onClick={this.goToPickup.bind(this, item.pickup_id)}>
                                                    <td className={clss} style={style}>PIC-{item.pickup_id}</td>
                                                    <td className={clss} style={style}>{item.d_eff}</td>
                                                    <td className={clss}
                                                        style={style}>{(item.src == 0 ? "--" : (typeof this.state.inventory_sources[item.src] != "undefined" ? this.state.inventory_sources[item.src].name : "?"))}</td>
                                                    <td className={clss}
                                                        style={style}>{(item.dst == 0 ? "--" : (typeof this.state.inventory_sources[item.dst] != "undefined" ? this.state.inventory_sources[item.dst].name : "?"))}</td>
                                                    <td className={clss} style={style}>{notes}<Link to={url}>{order_text}</Link></td>
                                                    <td style={style}>{(typeof this.state.products[item.prod] == "undefined" ? "?" : this.state.products[item.prod].name)}</td>
                                                    <td style={style} className="text-right">{quantity}</td>
                                                </tr>
                                            )
                                        }
                                    }, this)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (InventoryTransactions);
