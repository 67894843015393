import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Button, InputGroup, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledButtonDropdown} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import PaymentStatus from '../Orders/PaymentStatus';
import DeliveryStatus from '../Orders/DeliveryStatus';
import {getInventorySources} from '../Inventory/InventoryFunctions.js';
import {getBanners} from "../Accounts/AccountFunctions";
import {getStaff} from '../Staff/StaffFunctions.js';
import {getProductsParent} from '../Products/ProductFunctions.js';
import {getCategories} from '../Categories/CategoryFunctions.js';
import {getFactors} from '../Common/Factors.js';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';
import {getOwners} from "../Owners/OwnerFunctions";

moment.tz.setDefault("America/Toronto");

var money = require("money-math");


class OrderHistory extends React.Component {
    constructor(props) {
        super(props);

        this.updateDates = this.updateDates.bind(this);

        this.state = {
            orders: [],
            subtotal: 0,
            tax: 0,
            deposit: 0,
            credit_applied: 0,
            total: 0,
            num_50l: 0,
            num_30l: 0,
            num_355ml: 0,
            num_475ml: 0,
            hl: 0,
            loading: true,
            staff: [],
            // default search settings
            payment_method: (localStorage.getItem('payment_method') != null ? localStorage.getItem('payment_method') : null),
            payment_status: (localStorage.getItem('payment_status') != null ? localStorage.getItem('payment_status') : 2),
            inventory_source_id: (localStorage.getItem('inventory_source_id') != null ? localStorage.getItem('inventory_source_id') : null),
            delivery_status: (localStorage.getItem('delivery_status') != null ? localStorage.getItem('delivery_status') : null),
            account_type: (localStorage.getItem('account_type') != null ? localStorage.getItem('account_type') : 99),
            startDate: (localStorage.getItem('startDate') != null ? moment(localStorage.getItem('startDate')) : moment().subtract(29, 'days')),
            endDate: (localStorage.getItem('endDate') != null ? moment(localStorage.getItem('endDate')) : moment().endOf('day')),
            order_type: (localStorage.getItem('order_type') != null ? localStorage.getItem('order_type') : 1),
            account_rep: (localStorage.getItem('account_rep') != 0 && localStorage.getItem('account_rep') != null ? localStorage.getItem('account_rep') : 0),
            // default search filter names
            company: (localStorage.getItem('company') != null ? localStorage.getItem('company') : null),
            companies_name: (localStorage.getItem('company_name') != null ? localStorage.getItem('company_name') : "All"),
            companies: [],
            payment_method_name: (localStorage.getItem('payment_method_name') != null ? localStorage.getItem('payment_method_name') : "All"),
            payment_status_name: (localStorage.getItem('payment_status_name') != null ? localStorage.getItem('payment_status_name') : "Paid  & Receivable"),
            delivery_status_name: (localStorage.getItem('delivery_status_name') != null ? localStorage.getItem('delivery_status_name') : "All"),
            order_type_name: (localStorage.getItem('order_type_name') != null ? localStorage.getItem('order_type_name') : "Real"),
            delivery_method_name: (localStorage.getItem('delivery_method_name') != null ? localStorage.getItem('delivery_method_name') : "All"),
            account_type_name: (localStorage.getItem('account_type_name') != null ? localStorage.getItem('account_type_name') : "Retail & OP"),
            account_rep_name: (localStorage.getItem('account_rep') != 0 && localStorage.getItem('account_rep') != null ? localStorage.getItem('account_rep_name') : "All"),
            banner: (localStorage.getItem('banner') != null ? localStorage.getItem('banner') : null),
            product_parent_name: (localStorage.getItem('product_parent_name') != null ? localStorage.getItem('product_parent_name') : "All"),
            product_parent_id: (localStorage.getItem('product_parent_id') != null ? localStorage.getItem('product_parent_id') : null),
            factor_name: (localStorage.getItem('factor_name') != null ? localStorage.getItem('factor_name') : "All"),
            factor_id: (localStorage.getItem('factor_id') != null ? localStorage.getItem('factor_id') : null),
            category_name: (localStorage.getItem('category_name') != null ? localStorage.getItem('category_name') : "All"),
            category_id: (localStorage.getItem('category_id') != null ? localStorage.getItem('category_id') : null),
            categories: [],
            products: {},
            products_sort: [],
            factors: [],
            account_types: {
                0: "Special",
                1: "On-Premise - Cat A",
                9: "On-Premise - Cat B",
                2: "TBS",
                3: "LCBO",
                4: "Individuals",
                5: "Grocery",
                6: "Agency",
                7: "LCBO Warehouse",
                8: "NSLC",
                10: "Duty-Free",
                11: "Convenience",
                97: "All OP",
                98: "All Retail",
                99: "All OP & Retail"
            },
            payment_methods: {1: "Credit Card", 2: "Cheque", 3: "ETF", 4: "Shopify", 6: "E-Transfer"},
            payment_statuses: {0: "Receivable (Not Paid)", 1: "Paid", 2: "Paid & Receivable", 3: "Error/Declined", 4: "Bad Debt", 5: "Promo"},
            delivery_statuses: {0: "Processing", 1: "Scheduled", 2: "Out for Delivery", 3: "Delivered", 9: "Held - Pending Approval"},
            order_types: {0: "Test", 1: "Real"},
            inventory_sources: [],
            banners: [],
            ranges: {
                'Today': [moment(), moment()],
                'Last 7 Days': [moment().subtract(6, 'days'), moment().endOf('day')],
                'Last 30 Days': [moment().subtract(29, 'days'), moment().endOf('day')],
                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'All Time': [moment("01 Jun 2018"), moment().endOf('day')]
            },
        };
    }

    componentDidMount() {
        document.title = "Orders | BevOps";
        var self = this;
        getStaff(function (staff) {
            staff[0] = {
                'first_name': 'All',
                'last_name': ''
            };
            staff['null'] = {
                'first_name': '(Unassigned)',
                'last_name': ''
            };
            getInventorySources('can_deliver', function (inventory_sources) {
                inventory_sources['0'] = {
                    'inventory_source_id': 0,
                    'name': '---'
                };
                getBanners(function (banners) {
                    getProductsParent(function (products) {
                        getFactors(function (factors) {
                            getCategories(function (categories) {
                                getOwners(function (owners) {
                                    var products_sort = []
                                    Object.keys(products).map(function (product_parent_id, key) {
                                        products[product_parent_id].visibile = false;
                                        products_sort.push({id: product_parent_id, name: products[product_parent_id].name})
                                    });
                                    products_sort = products_sort.sort((a, b) => {
                                        if (a.name.toLowerCase() < b.name.toLowerCase()) {
                                            return -1;
                                        }
                                        if (a.name.toLowerCase() > b.name.toLowerCase()) {
                                            return 1;
                                        }
                                        return 0;
                                    });
                                    self.setState({
                                        inventory_sources: inventory_sources,
                                        staff: staff,
                                        companies: owners,
                                        banners: banners,
                                        products: products,
                                        products_sort: products_sort,
                                        categories: categories,
                                        factors: factors
                                    });
                                    self.getOrders();
                                });
                            });
                        });
                    });
                });
            });
        });
    }


    // on date range change
    updateDates(event, picker) {
        if (event.type == "apply") {
            localStorage.setItem('startDate', picker.startDate);
            localStorage.setItem('endDate', picker.endDate);
            this.setState({
                startDate: picker.startDate,
                endDate: picker.endDate
            }, () => {
                this.getOrders()
            });
        }
    }

    changeCompany = (company) => {
        if (company == null) {
            var companies_name = "All"
        } else {
            var companies_name = this.state.companies[company].name
        }
        localStorage.setItem('company', company);
        localStorage.setItem('company_name', companies_name);
        this.setState({
            company: company,
            companies_name: companies_name
        }, () => {
            this.getOrders()
        });
    }

    changePaymentMethod = (payment_method) => {
        if (payment_method == null) {
            var payment_method_name = "All"
        } else {
            var payment_method_name = this.state.payment_methods[payment_method]
        }
        localStorage.setItem('payment_method', payment_method);
        localStorage.setItem('payment_method_name', payment_method_name);
        this.setState({
            payment_method: payment_method,
            payment_method_name: payment_method_name
        }, () => {
            this.getOrders()
        });
    }

    changeBanner = (banner) => {
        if (banner == "All") {
            banner = null;
        }
        localStorage.setItem('banner', banner);
        this.setState({
            banner: banner,
        }, () => {
            this.getOrders()
        });
    };

    changePaymentStatus = (payment_status) => {
        if (payment_status == null) {
            var payment_status_name = "All"
        } else {
            var payment_status_name = this.state.payment_statuses[payment_status]
        }
        localStorage.setItem('payment_status', payment_status);
        localStorage.setItem('payment_status_name', payment_status_name);
        this.setState({
            payment_status: payment_status,
            payment_status_name: payment_status_name
        }, () => {
            this.getOrders()
        });
    }

    changeDeliveryMethod = (inventory_source_id) => {
        if (inventory_source_id == null) {
            var delivery_method_name = "All"
        } else {
            var delivery_method_name = this.state.inventory_sources[inventory_source_id].name
        }
        localStorage.setItem('inventory_source_id', inventory_source_id);
        localStorage.setItem('delivery_method_name', delivery_method_name);
        this.setState({
            inventory_source_id: inventory_source_id,
            delivery_method_name: delivery_method_name
        }, () => {
            this.getOrders()
        });
    }

    changeProduct = (product_parent_id) => {
        if (product_parent_id == null) {
            var product_parent_name = "All"
        } else {
            var product_parent_name = this.state.products[product_parent_id].name
        }
        localStorage.setItem('product_parent_name', product_parent_name);
        localStorage.setItem('product_parent_id', product_parent_id);
        this.setState({
            product_parent_id: product_parent_id,
            product_parent_name: product_parent_name
        }, () => {
            this.getOrders();
        });
    };

    changeCategory = (category_id) => {
        if (category_id == null) {
            var category_name = "All"
        } else {
            var category_name = this.state.categories[category_id].name
        }
        localStorage.setItem('category_name', category_name);
        localStorage.setItem('category_id', category_id);
        this.setState({
            category_id: category_id,
            category_name: category_name
        }, () => {
            this.getOrders();
        });
    };

    changeFactor = (factor_id) => {
        if (factor_id == null) {
            var factor_name = "All"
        } else {
            var factor_name = this.state.factors[factor_id].name
        }
        localStorage.setItem('factor_name', factor_name);
        localStorage.setItem('factor_id', factor_id);
        this.setState({
            factor_id: factor_id,
            factor_name: factor_name
        }, () => {
            this.getOrders();
        });
    };

    changeDeliveryStatus = (delivery_status) => {
        if (delivery_status == null) {
            var delivery_status_name = "All"
        } else {
            var delivery_status_name = this.state.delivery_statuses[delivery_status]
        }
        localStorage.setItem('delivery_status', delivery_status);
        localStorage.setItem('delivery_status_name', delivery_status_name);
        this.setState({
            delivery_status: delivery_status,
            delivery_status_name: delivery_status_name
        }, () => {
            this.getOrders()
        });
    };

    changeAccountType = (account_type) => {
        if (account_type == null) {
            var account_type_name = "All"
        } else {
            var account_type_name = this.state.account_types[account_type]
        }
        localStorage.setItem('account_type', account_type);
        localStorage.setItem('account_type_name', account_type_name);
        this.setState({
            account_type: account_type,
            account_type_name: account_type_name
        }, () => {
            this.getOrders()
        });
    }

    changeOrderType = (order_type) => {
        if (order_type == null) {
            var order_type_name = "All"
        } else {
            var order_type_name = this.state.order_types[order_type]
        }
        localStorage.setItem('order_type', order_type);
        localStorage.setItem('order_type_name', order_type_name);
        this.setState({
            order_type: order_type,
            order_type_name: order_type_name
        }, () => {
            this.getOrders()
        });
    };

    changeRep = (sales_rep_id) => {
        var account_rep_name = this.state.staff[sales_rep_id].first_name + " " + this.state.staff[sales_rep_id].last_name;
        localStorage.setItem('account_rep', sales_rep_id);
        localStorage.setItem('account_rep_name', account_rep_name);
        this.setState({
            account_rep: sales_rep_id,
            account_rep_name: account_rep_name
        }, () => {
            this.getOrders()
        });
    };

    goToOrder = (order_id) => {
        this.props.history.push("/order/" + order_id);
    };

    refreshSearch = event => {
        this.getOrders();
    };

    getOrders() {
        this.setState({
            loading: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/orders/?payment_method=' + self.state.payment_method + "&payment_status=" + self.state.payment_status + "&shipping_status=" + self.state.delivery_status + "&inventory_source_id=" + self.state.inventory_source_id + "&startDate=" + self.state.startDate + "&endDate=" + self.state.endDate + "&order_type=" + self.state.order_type + "&account_type=" + self.state.account_type + "&sales_rep_id=" + self.state.account_rep + "&company_id=" + self.state.company + "&banner=" + self.state.banner + "&product_parent_id=" + self.state.product_parent_id + "&category_id=" + self.state.category_id + "&factor_id=" + self.state.factor_id)
            .then(function (response) {
                var subtotal = money.floatToAmount(0);
                var tax = money.floatToAmount(0);
                var deposit = money.floatToAmount(0);
                var total = money.floatToAmount(0);
                var credit_applied = money.floatToAmount(0);
                var num_50l = 0;
                var num_30l = 0;
                var num_355ml = 0;
                var num_473ml = 0;
                var hl = 0;
                var payable = money.floatToAmount(0);
                response.data.map(function (item, key) {
                    if (item.void_date == null) {
                        if (item.company_id == 1 || item.company_id == 7 || item.company_id == 15 || item.company_id == 16) {
                            subtotal = money.add(money.floatToAmount(item.subtotal), subtotal);
                            tax = money.add(money.floatToAmount(item.tax), tax);
                            deposit = money.add(money.floatToAmount(item.deposit), deposit);
                            total = money.add(money.floatToAmount(item.total), total);
                            payable = money.add(money.floatToAmount(item.total - item.paid), payable);
                            credit_applied = money.add(money.floatToAmount(item.credit_applied - item.credit_applied), credit_applied);
                        }
                        num_50l += item.num_50l;
                        num_30l += item.num_30l;
                        num_355ml += item.num_355ml;
                        num_473ml += item.num_473ml;
                        hl += item.hl;
                    }
                });
                self.setState({
                    orders: response.data,
                    loading: false,
                    subtotal: money.format("USD", subtotal),
                    tax: money.format("USD", tax),
                    deposit: money.format("USD", deposit),
                    total: money.format("USD", total),
                    payable: money.format("USD", payable),
                    credit_applied: money.format("USD", credit_applied),
                    num_50l: num_50l,
                    num_30l: num_30l,
                    num_355ml: num_355ml,
                    num_473ml: num_473ml,
                    hl: hl
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        orders: [],
                        loading: false
                    });
                }
            });
    }

    export = () => {
        var currentdate = new Date();
        var csv = "Filters:\n"
        csv += ',Period:,,' + this.state.startDate.format('MMM. D/YY') + ' - ' + this.state.endDate.format('MMM. D/YY') + '\n';
        csv += ',Payment Type:,,' + this.state.payment_method_name + '\n';
        csv += ',Payment Status:,,' + this.state.payment_status_name + '\n';
        csv += ',Delivery By:,,' + this.state.delivery_method_name + '\n';
        csv += ',Delivery Status:,,' + this.state.delivery_status_name + '\n';
        csv += ',Company:,,' + this.state.companies_name + '\n';
        csv += ',Product:,,' + this.state.product_parent_name + '\n';
        csv += ',Category:,,' + this.state.category_name + '\n';
        csv += ',Account Type:,,' + this.state.account_type_name + '\n';
        csv += ',Banner:,,' + (String(this.state.banner) == "null" ? "All" : this.state.banner) + '\n';
        csv += ',Rep:,,' + this.state.account_rep_name + '\n';
        csv += ',Type:,,' + this.state.order_type_name + '\n';
        csv += '\n';
        csv += 'Generated:,' + currentdate + '\n';
        csv += '\n';
        csv += 'Order #, Date, Account Name, Address, City, Channel, Sales Rep, Delivery Status, Delivery Method, Payment Method, Payment Status, Subtotal, Deposit, Tax, Total, Paid, Payable, PO Num, Num 50L, Num 30L, Num 355ml x24, Num 473ml x24, HL\n';
        for (var i = 0; i < this.state.orders.length; i++) {
            var order = this.state.orders[i];
            var inv_name = "-";
            if (typeof this.state.inventory_sources[order.inventory_source_id] != "undefined") {
                inv_name = this.state.inventory_sources[order.inventory_source_id].name;
            }
            var rep = "";
            if (typeof this.state.staff[order.sales_rep_id] != "undefined") {
                rep = this.state.staff[order.sales_rep_id].first_name + " " + this.state.staff[order.sales_rep_id].last_name;
            }
            if (rep == "(Unassigned) ") {
                rep = "";
            }
            csv += '"' + order.order_id + '","' + order.date_short + '","' + order.name + '","' + order.address1 + '","' + order.city + '","' + this.state.account_types[order.account_type] + '","' + rep + '","' + this.state.delivery_statuses[order.shipping_status] + '","' + inv_name + '","' + this.state.payment_methods[order.payment_method] + '","' + (this.state.void_date != null ? "VOID" : this.state.payment_statuses[order.is_paid]) + '","' + order.subtotal + '","' + order.deposit + '",' + order.tax + ',"' + order.total + '","' + order.paid + '","' + (order.is_paid != 0 ? 0 : (order.total - order.paid)) + '","' + (order.purchase_order != null ? order.purchase_order : "") + '",' + order.num_50l + ',' + order.num_30l + ',' + order.num_355ml + ',' + order.num_473ml + ',' + order.hl + '\n';
        }
        const blob = new Blob([csv], {type: "text/csv"});
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "Order History.csv";
        link.href = url;
        link.click();
    }

    render() {

        // date range selector variables
        let start = this.state.startDate.format('MMM. D/YY');
        let end = this.state.endDate.format('MMM. D/YY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }

        return (
            <ContentWrapper>
                <div className="content-heading  mb-1">
                    <div>Order History</div>
                    <div className="ml-auto">
                        <Button onClick={this.refreshSearch}><i className="fas fa-sync-alt"></i></Button>
                    </div>
                </div>
                <div className="mb-1 row" style={{zIndex: 1000}}>
                    <div className="col col-8 mb-2">
                        <div className="text-muted font-weight-bold">ORDER DETAILS</div>
                        <div className="form-inline">
                            <DateRangePicker
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                maxDate={moment()}
                                ranges={this.state.ranges}
                                onEvent={this.updateDates}
                                autoUpdateInput={true}
                            >
                                <Button className="selected-date-range-btn" color="primary">
                                    Period: <b>{label}</b> <span className="fas fa-caret-down fa-sm"></span>
                                </Button>
                            </DateRangePicker>
                            <InputGroup>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle caret color={(String(this.state.payment_method) == "null" ? "primary" : "warning")} style={{fontSize: '13px', width: 'auto'}}>
                                        Payment Type: <b>{this.state.payment_method_name}</b>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem key="null" onClick={() => this.changePaymentMethod(null)}>All</DropdownItem>
                                        <DropdownItem divider/>
                                        {Object.keys(this.state.payment_methods).map((key, i) =>
                                            <DropdownItem key={key} onClick={() => this.changePaymentMethod(key)}>{this.state.payment_methods[key]}</DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </InputGroup>
                            <InputGroup>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color={(String(this.state.payment_status) == "null" ? "primary" : "warning")} caret style={{fontSize: '13px', width: 'auto'}}>
                                        Payment Status: <b>{this.state.payment_status_name}</b>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem key="null" onClick={() => this.changePaymentStatus(null)}>All</DropdownItem>
                                        <DropdownItem divider/>
                                        {Object.keys(this.state.payment_statuses).map((key, i) =>
                                            <DropdownItem key={key} onClick={() => this.changePaymentStatus(key)}>{this.state.payment_statuses[key]}</DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </InputGroup>
                            <InputGroup>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color={(String(this.state.inventory_source_id) == "null" ? "primary" : "warning")} caret
                                                    style={{fontSize: '13px', width: 'auto'}}>
                                        Delivery By: <b>{this.state.delivery_method_name}</b>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem key="null" onClick={() => this.changeDeliveryMethod(null)}>All</DropdownItem>
                                        <DropdownItem divider/>
                                        {Object.keys(this.state.inventory_sources).map((inventory_source_id, key) =>
                                            <DropdownItem key={inventory_source_id}
                                                          onClick={() => this.changeDeliveryMethod(inventory_source_id)}>{this.state.inventory_sources[inventory_source_id].name}</DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </InputGroup>
                            <InputGroup>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color={(String(this.state.delivery_status) == "null" ? "primary" : "warning")} caret style={{fontSize: '13px', width: 'auto'}}>
                                        Delivery Status: <b>{this.state.delivery_status_name}</b>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem key="null" onClick={() => this.changeDeliveryStatus(null)}>All</DropdownItem>
                                        <DropdownItem divider/>
                                        {Object.keys(this.state.delivery_statuses).map((key, i) =>
                                            <DropdownItem key={key} onClick={() => this.changeDeliveryStatus(key)}>{this.state.delivery_statuses[key]}</DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </InputGroup>
                            <InputGroup>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle caret color={(String(this.state.company) == "null" ? "primary" : "warning")} style={{fontSize: '13px', width: 'auto'}}>
                                        Companies: <b>{this.state.companies_name}</b>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem key="null" onClick={() => this.changeCompany(null)}>All</DropdownItem>
                                        <DropdownItem divider/>
                                        {Object.keys(this.state.companies).map((key, i) => {
                                            if (
                                                (this.state.companies[key].product_owner_id == 1 || this.state.companies[key].product_owner_id == 7 || this.state.companies[key].product_owner_id == 15 || this.state.companies[key].product_owner_id == 16) &&
                                                (localStorage.getItem('companies') == "ALL" || localStorage.getItem('companies').indexOf(',' + key + ',') != -1)) {
                                                    return (<DropdownItem key={key} onClick={() => this.changeCompany(key)}>{this.state.companies[key].name}</DropdownItem>)
                                                }
                                            }
                                        )}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </InputGroup>
                            <InputGroup>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle caret color={(String(this.state.product_parent_id) == "null" ? "primary" : "warning")}
                                                    style={{fontSize: '13px', width: 'auto'}}>
                                        Product: {this.state.product_parent_name}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem key="null" onClick={() => this.changeProduct(null)}>All</DropdownItem>
                                        <DropdownItem divider/>
                                        {this.state.products_sort.map((product) => {
                                            if (product.is_deleted != 1) {
                                                return (<DropdownItem key={product.id} onClick={() => this.changeProduct(product.id)}>{product.name}</DropdownItem>)
                                            }
                                        })}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </InputGroup>
                            <InputGroup>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle caret color={(String(this.state.factor_id) == "null" ? "primary" : "warning")}
                                                    style={{fontSize: '13px', width: 'auto'}}>
                                        Factor: {this.state.factor_name}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem key="null" onClick={() => this.changeFactor(null)}>All</DropdownItem>
                                        <DropdownItem divider/>
                                        {Object.keys(this.state.factors).map(function (factor_id) {
                                            return (
                                                <DropdownItem key={factor_id}
                                                              onClick={() => this.changeFactor(factor_id)}>{this.state.factors[factor_id].name}</DropdownItem>
                                            )
                                        }, this)}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </InputGroup>
                            <InputGroup>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle caret color={(String(this.state.category_id) == "null" ? "primary" : "warning")} style={{fontSize: '13px', width: 'auto'}}>
                                        Category: {this.state.category_name}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem key="null" onClick={() => this.changeCategory(null)}>All</DropdownItem>
                                        <DropdownItem divider/>
                                        {Object.keys(this.state.categories).map((key, i) =>
                                            <DropdownItem key={key}
                                                          onClick={() => this.changeCategory(this.state.categories[key].product_category_id)}>{this.state.categories[key].name}</DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </InputGroup>
                        </div>
                    </div>
                    <div className="col col-4 mb-2">
                        <div className="text-muted font-weight-bold">ACCOUNT FILTERS</div>
                        <div className="form-inline">
                            <InputGroup>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color={(String(this.state.account_type) == "null" ? "primary" : "warning")} caret style={{fontSize: '13px', width: 'auto'}}>
                                        Account Type: <b>{this.state.account_type_name}</b>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem key="null" onClick={() => this.changeAccountType(null)}>All</DropdownItem>
                                        <DropdownItem divider/>
                                        {Object.keys(this.state.account_types).map((key, i) =>
                                            <DropdownItem key={key} onClick={() => this.changeAccountType(key)}>{this.state.account_types[key]}</DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </InputGroup>
                            <InputGroup>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color={(String(this.state.banner) == "null" ? "primary" : "warning")} caret style={{fontSize: '13px', width: 'auto'}}>
                                        Banner: <b>{(String(this.state.banner) == "null" ? "All" : this.state.banner)}</b>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem key="null" onClick={() => this.changeBanner(null)}>All</DropdownItem>
                                        <DropdownItem divider/>
                                        {this.state.banners.map((banner, i) =>
                                            <DropdownItem key={banner} onClick={() => this.changeBanner(banner)}>{banner}</DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </InputGroup>
                            <InputGroup direction="down">
                                <UncontrolledButtonDropdown direction="down">
                                    <DropdownToggle direction="down" color={(String(this.state.account_rep) == "0" ? "primary" : "warning")} caret
                                                    style={{fontSize: '13px', width: 'auto'}}>
                                        Rep: <b>{this.state.account_rep_name}</b>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem key="null" onClick={() => this.changeRep(0)}>All</DropdownItem>
                                        <DropdownItem divider/>
                                        {Object.keys(this.state.staff).map((sales_rep_id, i) => {
                                            if (this.state.staff[sales_rep_id].status == 1) {
                                                return (<DropdownItem key={sales_rep_id}
                                                                      onClick={() => this.changeRep(sales_rep_id)}>{this.state.staff[sales_rep_id].first_name} {this.state.staff[sales_rep_id].last_name}</DropdownItem>)
                                            }
                                        }, this)}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </InputGroup>
                            <InputGroup>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color={(String(this.state.order_type) == "null" ? "primary" : "warning")} caret style={{fontSize: '13px', width: 'auto'}}>
                                        Type: <b>{this.state.order_type_name}</b>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem key="null" onClick={() => this.changeOrderType(null)}>All</DropdownItem>
                                        <DropdownItem divider/>
                                        {Object.keys(this.state.order_types).map((key, i) =>
                                            <DropdownItem key={key} onClick={() => this.changeOrderType(key)}>{this.state.order_types[key]}</DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </InputGroup>
                        </div>
                    </div>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div className={(this.state.loading ? "invisible" : "")}>
                        <div className="card-header">
                            <a href={API_ROOT + '/orders/export/?payment_method=' + this.state.payment_method + "&payment_status=" + this.state.payment_status + "&shipping_status=" + this.state.delivery_status + "&inventory_source_id=" + this.state.inventory_source_id + "&startDate=" + this.state.startDate + "&endDate=" + this.state.endDate + "&order_type=" + this.state.order_type + "&company_id=" + this.state.company}
                               className="btn btn-secondary btn-xs float-right">
                                <i className="fas fa-cloud-download-alt"></i> Quickbooks Export
                            </a>
                            <div className="btn btn-secondary btn-xs float-right mr-1" onClick={this.export}>
                                <i className="fas fa-download"></i> Summary Export
                            </div>
                            <div className="card-title">{this.state.orders.length} Matching Orders</div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className={(this.state.orders.length > 0 && !this.state.loading ? "table table-hover table-pointer text-right" : "d-none")}>
                                    <thead>
                                    <tr>
                                        <th className="text-left">#</th>
                                        <th className="text-left">Account</th>
                                        <th className="d-none d-lg-table-cell">Subtotal</th>
                                        <th className="d-none d-lg-table-cell">Tax</th>
                                        <th className="d-none d-lg-table-cell">Deposit</th>
                                        <th>Total</th>
                                        <th className="d-none d-lg-table-cell">Credit</th>
                                        <th className="d-none d-lg-table-cell">Payable</th>
                                        <th className="d-none d-sm-table-cell text-left">Del. Method</th>
                                        <th className="d-none d-lg-table-cell">Pay. Method</th>
                                        <th className="text-left">Date/Time</th>
                                        <th className="text-left">Est. Delivery</th>
                                        <th className="d-none d-sm-table-cell">Pay. Status</th>
                                        <th className="d-none d-sm-table-cell">Del. Status</th>
                                        <th className="d-none d-md-table-cell">50L</th>
                                        <th className="d-none d-md-table-cell">30L</th>
                                        <th className="d-none d-md-table-cell">355ml x24</th>
                                        <th className="d-none d-md-table-cell">473ml x24</th>
                                        <th className="d-none d-md-table-cell">HL</th>
                                        <th className="d-none d-sm-table-cell">PO</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.orders.map(function (item, key) {
                                        var payment_method = "Cheque";
                                        if (item.payment_method == 1) {
                                            payment_method = "Credit Card";
                                        } else if (item.payment_method == 3) {
                                            payment_method = "EFT";
                                        } else if (item.payment_method == 4) {
                                            payment_method = "Shopify";
                                        }
                                        var inv_name = "-";
                                        if (typeof this.state.inventory_sources[item.inventory_source_id] != "undefined") {
                                            inv_name = this.state.inventory_sources[item.inventory_source_id].name;
                                        }
                                        var est_delivery = moment(item.est_delivery)
                                        var today = moment().startOf('day');
                                        var muted = false;
                                        var days = Math.round(moment.duration(est_delivery - today).asDays());
                                        if (isNaN(days)) {
                                            days = "???"
                                        } else {
                                            if (days == 0) {
                                                days = "today"
                                            } else if (days < 0) {
                                                days = (days * -1) + " days ago"
                                                muted = true
                                            } else {
                                                days += " days"
                                            }
                                        }
                                        return (
                                            <tr key={key} onClick={this.goToOrder.bind(this, item.order_id)}
                                                style={{"text-decoration": (item.is_paid === null || item.void_date != null ? "line-through" : "none")}}>
                                                <td className="text-left">{item.order_id}</td>
                                                <td className="text-left">{item.name}<br></br><small>{item.address1}, {item.city}</small></td>
                                                <td className="d-none d-lg-table-cell">${item.subtotal.toFixed(2)}</td>
                                                <td className="d-none d-lg-table-cell">${item.tax.toFixed(2)}</td>
                                                <td className="d-none d-lg-table-cell">${item.deposit.toFixed(2)}</td>
                                                <td>${item.total.toFixed(2)}</td>
                                                <td className="d-none d-lg-table-cell">${item.credit_applied.toFixed(2)}</td>
                                                <td className="d-none d-lg-table-cell">${(item.is_paid != 0 ? 0 : (item.total - item.paid)).toFixed(2)}</td>
                                                <td className="d-none d-sm-table-cell text-left">{inv_name}</td>
                                                <td className="d-none d-lg-table-cell">{payment_method}</td>
                                                <td className="text-left">{item.date_short}</td>
                                                <td className={(muted ? "text-left text-muted" : "text-left")} title={est_delivery.format("MMM DD")}>{days}</td>
                                                <td className="d-none d-sm-table-cell">
                                                    <PaymentStatus is_paid={item.is_paid} void_date={item.void_date} company_id={item.company_id}/>
                                                </td>
                                                <td className="d-none d-sm-table-cell">
                                                    <DeliveryStatus shipping_status={item.shipping_status} company_id={item.company_id}/>
                                                </td>
                                                <td className="d-none d-md-table-cell">{item.num_50l}</td>
                                                <td className="d-none d-md-table-cell">{item.num_30l}</td>
                                                <td className="d-none d-md-table-cell">{item.num_355ml}</td>
                                                <td className="d-none d-md-table-cell">{item.num_473ml}</td>
                                                <td className="d-none d-md-table-cell">{item.hl.toFixed(1)}</td>
                                                <td className="d-none d-sm-table-cell">{item.purchase_order}</td>
                                            </tr>
                                        )
                                    }, this)}
                                    </tbody>
                                    <tfoot>
                                    <tr className="font-weight-bold bg-gray-lighter">
                                        <td className="text-left"></td>
                                        <td className="text-right">Total:</td>
                                        <td className="d-none d-lg-table-cell">${this.state.subtotal}</td>
                                        <td className="d-none d-lg-table-cell">${this.state.tax}</td>
                                        <td className="d-none d-lg-table-cell">${this.state.deposit}</td>
                                        <td>${this.state.total}</td>
                                        <td className="d-none d-lg-table-cell">${this.state.credit_applied}</td>
                                        <td className="d-none d-lg-table-cell">${this.state.payable}</td>
                                        <td className="d-none d-sm-table-cell text-left"></td>
                                        <td className="d-none d-lg-table-cell"></td>
                                        <td></td>
                                        <td></td>
                                        <td className="d-none d-sm-table-cell"></td>
                                        <td className="d-none d-sm-table-cell"></td>
                                        <td className="d-none d-md-table-cell">{this.state.num_50l}</td>
                                        <td className="d-none d-md-table-cell">{this.state.num_30l}</td>
                                        <td className="d-none d-md-table-cell">{parseFloat(this.state.num_355ml).toFixed(1)}</td>
                                        <td className="d-none d-md-table-cell">{parseFloat(this.state.num_473ml).toFixed(1)}</td>
                                        <td className="d-none d-md-table-cell">{parseFloat(this.state.hl).toFixed(1)}</td>
                                        <td className="d-none d-sm-table-cell"></td>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (OrderHistory);
