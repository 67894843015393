import React, {Component} from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Link} from 'react-router-dom';
import {
    Card,
    CardBody,
    Row,
    Col,
    InputGroup,
    Button,
    Input,
    InputGroupText,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    InputGroupAddon,
    Label
} from 'reactstrap';
import axios from 'axios';
import { API_ROOT } from '../../api-config';
import Swal from 'sweetalert2'
import 'lity/dist/lity.min.js';
import 'lity/dist/lity.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment-timezone';
import AddUser from '../Users/AddUser';
import EditUser from '../Users/EditUser';
import ManageCard from '../Accounts/ManageCard';
import PaymentStatus from '../Orders/PaymentStatus';
import DeliveryStatus from '../Orders/DeliveryStatus';
import {getStaff} from '../Staff/StaffFunctions.js';
import {getInventorySources} from "../Inventory/InventoryFunctions";
import AccountStatus, {getDeliveryDate} from "../Accounts/AccountFunctions";
import CallNotes from "../Accounts/CallNotes";
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';

moment.tz.setDefault("America/Toronto");

var money = require("money-math");

class AccountPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order_id: null,
            account_id: null,
            subtotal: 0,
            tax: 0,
            deposit: 0,
            total: 0,
            items: [],
            staff: [],
            status_select: false,
            type_select: false,
            sales_rep_select: false,
            sales_rep_name: "- Unassigned -",
            call_frequency_select: false,
            account: {
                status: 1,
                loading: true,
                name: "",
                license_num: "",
                address1: "",
                address2: "",
                city: "",
                province: "",
                postal_code: "",
                phone: "",
                website: "",
                delivery_instructions: "",
                sales_rep_id: null,
                manager_name: "",
                manager_email: "",
                manager_phone: "",
                banner: "",
                call_frequency: 0,
                account_type: null,
                tng_card_code: "",
                delivery_day: "Daily",
                delivery_week: "Weekly",
                inventory_source_id: null,
                credit_limit: 0,
                terms:0,
                payment_type: 0,
                credit: {
                    amount: 0,
                    details: []
                }
            },
            summary: {
                loading: true,
                date_opened: "-",
                since_last_order: 0,
                avg_order_duration: 0,
                num_orders: "-",
                avg_order: 0,
                total_spent: 0
            },
            users: {
                loading: true,
                list: []
            },
            orders: {
                list: [],
                loading: true,
                subtotal: 0,
                tax: 0,
                deposit: 0,
                total: 0,
                owing: 0,
                kegs: 0,
                cases: 0,
                total_owing: money.floatToAmount(0)
            },
            bols: {
                list: [],
                loading: true,
                kegs: 0,
                cases: 0
            },
            dropdowns: {
                is_cold: false,
            },
            inventory_sources: [],
            call_frequency_options: {
                0: "Non-Visited",
                7: "Weekly (Tier 1)",
                14: "Bi-Weekly (Tier 2)",
                30: "Monthly (Tier 3)",
                90: "Quarterly (Tier 4)",
            },
            delivery_days: {
                "null": "---",
                "Daily": "Daily",
                "Monday": "Monday",
                "Tuesday": "Tuesday",
                "Wednesday": "Wednesday",
                "Thursday": "Thursday",
                "Friday": "Friday",
                "Saturday": "Saturday",
            },
            delivery_weeks: {
                "null": "---",
                "Weekly": "Weekly",
                "Odd": "Odd",
                "Even": "Even",
            },
            inventory_source_print: null,
            delivery_inventory_source_id: null,
            credit_history_modal: false,
            credit_add_modal: false,
            credit_add_reason: "",
            credit_add_amount: 0,
            spend_modal: false,
            spend_date: moment(),
            spend_amount: 0,
            spend_description: "",
            spends: {
                list: [],
                loading: true,
                total: money.floatToAmount(0)
            },
            downloading: false
        };
        this.accountChange = this.accountChange.bind(this);
        this.loadUsers = this.loadUsers.bind(this);
        this.loadAccount = this.loadAccount.bind(this);
        this.loadSpends = this.loadSpends.bind(this);
        this.updateField = this.updateField.bind(this);
        this.updateDates = this.updateDates.bind(this);
    }

    componentDidMount(prevProps) {
        this.loadAccount(this.props.match.params.account_id);
    }

    componentDidUpdate(prevProps) {
        if (typeof this.props.match.params != "undefined" && prevProps.match.params.account_id !== this.props.match.params.account_id) {
            this.loadAccount(this.props.match.params.account_id);
        }
    }

    loadAccount = (account_id) => {
        document.title = "Account # " + account_id + " | BevOps";
        this.setState({account_id: account_id})
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/account/' + account_id)
            .then(function (response) {
                getStaff(function (staff) {
                    response.data.loading = false;
                    staff['null'] = {
                        'first_name': '- Unassigned -',
                        'last_name': ''
                    };
                    var sales_rep_name = "Unassigned";
                    if (response.data.sales_rep_id > 0) {
                        sales_rep_name = staff[response.data.sales_rep_id].first_name + ' ' + staff[response.data.sales_rep_id].last_name;
                    }
                    self.setState({
                        account: response.data,
                        account_id: account_id,
                        staff: staff,
                        sales_rep_name: sales_rep_name
                    }, () => {
                        self.loadUsers()
                    });
                });
            })
            .catch(function (error) {
                /*if (error.response.status===401){
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }*/
            });
        axios.get(API_ROOT + '/account/summary/' + account_id)
            .then(function (response) {
                response.data.loading = false;
                self.setState({summary:response.data});
            })
            .catch(function (error) {
                if (error.response.status===401){
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
        axios.get(API_ROOT + '/orders/?account_id=' + account_id)
            .then(function (response) {
                var subtotal = money.floatToAmount(0);
                var tax = money.floatToAmount(0);
                var deposit = money.floatToAmount(0);
                var total = money.floatToAmount(0);
                var kegs = 0;
                var cases = 0;
                var total_owing = money.floatToAmount(0);
                response.data.map(function (item, key) {
                    if (item.is_paid !== null && item.void_date == null) {
                        if (item.company_id == 1 || item.company_id == 7 || item.company_id == 15 || item.company_id == 16) {
                            subtotal = money.add(money.floatToAmount(item.subtotal), subtotal);
                            tax = money.add(money.floatToAmount(item.tax), tax);
                            deposit = money.add(money.floatToAmount(item.deposit), deposit);
                            total = money.add(money.floatToAmount(item.total), total);
                            if (item.is_paid == 0) {
                                total_owing = money.add(money.subtract(money.floatToAmount(item.total), money.floatToAmount(item.paid)), total_owing);
                            }
                        }
                        kegs += (item.num_50l + item.num_30l);
                        cases += (item.num_355ml + item.num_473ml);
                    }
                });
                getInventorySources(false,function(inventory_sources) {
                    var inventory_source_print = "All"
                    self.setState(
                        {
                            inventory_source_print: inventory_source_print,
                            inventory_sources: inventory_sources,
                            orders: {
                                list: response.data,
                                loading: false,
                                subtotal: money.format("USD", subtotal),
                                tax: money.format("USD", tax),
                                deposit: money.format("USD", deposit),
                                total: money.format("USD", total),
                                kegs: kegs,
                                cases: cases,
                                total_owing: total_owing,
                            }
                        })
                    ;
                });
            })
            .catch(function (error) {
                if (error.response.status===401){
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });

        axios.get(API_ROOT + '/bols/?account_id=' + account_id)
            .then(function (response) {
                var kegs = 0;
                var cases = 0;
                response.data.map(function(item, key) {
                    kegs += item.kegs;
                    cases += item.cases;
                });
                var bols = {
                    list: response.data,
                    loading: false,
                    kegs: kegs,
                    cases: cases
                };
                self.setState({
                    bols:bols
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        orders: [],
                        loading: false
                    });
                }
            });

        this.loadSpends()
    }

    loadSpends() {
        var self = this;
        axios.get(API_ROOT + '/spends/' + this.props.match.params.account_id)
            .then(function (response) {
                var total = money.floatToAmount(0);
                response.data.map(function (item, key) {
                    total = money.add(money.floatToAmount(item.amount), total);
                });
                self.setState(
                    {
                        spends: {
                            list: response.data,
                            loading: false,
                            total: total,
                        }
                    })
                ;
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    loadUsers() {
        var self = this;
        axios.get(API_ROOT + '/users/?account_id=' + self.state.account_id)
            .then(function (response) {
                self.setState(
                    {
                        users: {
                            list: response.data,
                            loading: false,
                        }
                    })
                ;
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    accountChange(event) {
        var state = this.state.account;
        state[event.target.name] = event.target.value;
        this.setState({
            account: state
        });
    }

    updateField = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    adminLogin = (user_id) => {
        axios.defaults.withCredentials = true;
        var self = this;
        axios.post(API_ROOT + '/user/' + user_id + "/login")
            .then(function (response) {
                var win = window.open('https://' + window.location.hostname.replace('staff', 'order') + "/login/admin/" + response.data.auth_code + "/" + self.state.account_id, '_blank');
                if (win) {
                    toast.success("You've now logged-in in another window", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    win.focus();
                } else {
                    toast.error("ERROR: Please enable popups in your browser to login", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
            })
            .catch(function (error) {
                if (error.response.status===401){
                    this.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    };
    saveAccountDetails = async event => {
        if (event) {
            event.preventDefault();
        }
        var self = this;
        axios.defaults.withCredentials = true;
        axios.put(API_ROOT + '/account/' + this.props.match.params.account_id, {data: this.state.account})
            .then(function (response) {
                toast.success("Changes Saved", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    };

    // Changing sales rep
    editStatus = () => {
        this.setState({
            status_select: true
        })
    }
    editType = () => {
        this.setState({
            type_select: true
        })
    };
    editRep = () => {
        this.setState({
            sales_rep_select: true
        })
    };
    changeRep = (sales_rep_id) => {
        var account = this.state.account;
        if (sales_rep_id < 1) {
            var sales_rep_name = "Unassigned";
            account['call_frequency'] = 0;
        } else {
            var sales_rep_name = this.state.staff[sales_rep_id].first_name + " " + this.state.staff[sales_rep_id].last_name;
        }
        account['sales_rep_id'] = sales_rep_id;
        this.setState({
            sales_rep_name: sales_rep_name,
            sales_rep_select: false,
            type_select: false,
            account: account
        }, () => {
            this.saveAccountDetails();
        });
    };

    // Changign call frequency
    editCallFrequency = () => {
        this.setState({
            call_frequency_select: true
        })
    };
    changeCallFrequency = (call_frequency) => {
        var account = this.state.account;
        account['call_frequency'] = call_frequency;
        this.setState({
            account: account,
            call_frequency_select: false
        }, () => {
            this.saveAccountDetails();
        });
    };

    changeDelivery = (type, frequency) => {
        var account = this.state.account;
        if (type == "week") {
            account['delivery_week'] = frequency;
        } else if (type == "day") {
            account['delivery_day'] = frequency;
        } else {
            account['delivery_inventory_source_id'] = frequency;
        }
        this.setState({
            account: account,
        }, () => {
            this.saveAccountDetails();
        });
    };


    goToOrder = (order_id) => {
        this.props.history.push("/order/" + order_id);
    };

    createBOL = async event => {
        this.props.history.push("/bol/create/" + this.state.account_id);
    };

    goToBOL = (bol_id) => {
        this.props.history.push("/bol/" + bol_id);
    };

    credit_history_toggle = () => {
        this.setState({
            credit_history_modal: !this.state.credit_history_modal
        });
    };

    credit_add_toggle = () => {
        this.setState({
            credit_add_modal: !this.state.credit_add_modal
        });
    };

    saveAccountCredit = async event => {
        var self = this;
        if (self.state.credit_add_reason == "") {
            Swal("Error", "Please enter a reason for the account credit", "error");
        } else {
            axios.defaults.withCredentials = true;
            axios.post(API_ROOT + '/account/' + this.props.match.params.account_id + "/credit", {amount: this.state.credit_add_amount, reason: this.state.credit_add_reason})
                .then(function (response) {
                    toast.success("Changes Saved", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    self.setState({
                        credit_add_modal: false,
                        credit_add_amount: 0,
                        credit_add_reason: ""
                    });
                    self.loadAccount(self.state.account_id);
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                    }
                });
        }
    };

    spend_toggle = () => {
        this.setState({
            spend_modal: !this.state.spend_modal
        });
    };

    updateDates(event, picker) {
        event.preventDefault();
        if (event.type == "apply") {
            console.log(picker.startDate)
            this.setState({
                spend_date: picker.startDate,
            });
        }
    }

    getPDF = function () {
        this.setState({downloading: true});
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/account/' + this.state.account_id + "/statement")
            .then(function (response) {
                var element = document.createElement('a');
                element.setAttribute('href', response.data.url);
                if ("download" in document.createElement("a")) {
                    element.setAttribute('download', 'Account Statement #' + self.state.account_id + '.pdf');
                }
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                self.setState({downloading: false});
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
                self.setState({downloading: false});
            });
    }

    addSpend = () => {
        var self = this;
        self.state.spends.loading = true;
        this.setState({spends: self.state.spends});
        axios.defaults.withCredentials = true;
        axios.post(API_ROOT + '/spends/' + this.state.account_id, {date: self.state.spend_date, amount: self.state.spend_amount, description: self.state.spend_description})
            .then(function (response) {
                self.loadSpends();
                toast.success("Changes Saved", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                self.setState({
                    spend_modal: !false,
                    spend_amount: 0,
                    spend_description: "",
                });
            })
            .catch(function (error) {
                if (typeof error.response != "undefined") {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                    }
                }
            });
    };


    render() {
        var options_yesno = {0: "No", 1: "Yes"};
        var account_types = {
            0: "Special",
            1: "On-Premise",
            2: "TBS",
            3: "LCBO",
            4: "Individual",
            5: "Grocery",
            6: "Agency",
            7: "LCBO Warehouse",
            8: "NSLC",
            10: "Duty Free",
            11: "Convenience",
        };
        var payment_types = {
            2: "Unknown",
            1: "Credit Card",
            3: "EFT",
            6: "E-Transfer",
        };
        var options_status = {0: "Closed", 1: "Active", 2: "Lead"};
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <Link to="../accounts">Accounts</Link>&nbsp;/&nbsp; {this.state.account.name}
                    <div className="ml-auto">
                        <Button color="info" onClick={this.getPDF.bind(this)} disabled={this.state.downloading}>
                            <i className={(this.state.downloading ? "fa fa-spinner fa-spin" : "fa fa-list-alt")}></i> Statement</Button>
                    </div>
                </div>
                <div className={(this.state.account.credit_limit < this.state.orders.total_owing ? "alert alert-warning" : "d-none")}>
                    <i className="fas fa-exclamation-triangle pr-1"></i> Account balance owing (${money.format("USD", this.state.orders.total_owing)}) exceeds credit limit
                    (${this.state.account.credit_limit}). New COD orders must be manually set to "Processing" to ship.
                </div>
                <Row>
                    <Col lg={6}>
                        <Card className={(this.state.account.loading ? "card card-default whirl traditional" : "card card-default")}>
                            <CardBody>
                                <p className="lead bb">Details</p>
                                <form className="form-horizontal" onSubmit={this.saveAccountDetails}>
                                    <Row className="mb-0">
                                        <label className="col-xl-3 col-form-label pt-0">Account #</label>
                                        <div className="col-xl-9">
                                            <div className="text-bold">{this.state.account_id}</div>
                                        </div>
                                    </Row>
                                    <Row className="mb-0">
                                        <label className="col-xl-3 col-form-label pt-0">Type</label>
                                        <div className="col-xl-9">
                                            <span className={(this.state.type_select != false ? "d-none" : "text-bold")}>
                                                {account_types[this.state.account.account_type]}
                                                <Button size="sm" color="default" className="ml-1 far fa-edit" onClick={this.editType}></Button>
                                            </span>
                                            <InputGroup className={(this.state.type_select == false ? "d-none" : "")}>
                                                <UncontrolledButtonDropdown className="mt-0">
                                                    <DropdownToggle color="light" caret>
                                                        {account_types[this.state.account.account_type]}
                                                    </DropdownToggle>
                                                    <DropdownMenu container="body">
                                                        {Object.keys(account_types).map((key, i) =>
                                                            <DropdownItem name="account_type" key={key} value={key} onClick={this.accountChange}>{account_types[key]}</DropdownItem>
                                                        )}
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </InputGroup>
                                        </div>
                                    </Row>
                                    <Row className="mb-0">
                                        <label className="col-xl-3 col-form-label pt-0">Status</label>
                                        <div className="col-xl-9">
                                            <span className={(this.state.status_select != false ? "d-none" : "text-bold")}>
                                                <AccountStatus status={this.state.account.status}/>
                                                <Button size="sm" color="default" className="ml-1 far fa-edit" onClick={this.editStatus}></Button>
                                            </span>
                                            <InputGroup className={(this.state.status_select == false ? "d-none" : "")}>
                                                <UncontrolledButtonDropdown className="mt-0">
                                                    <DropdownToggle color="light" caret>
                                                        {options_status[this.state.account.status]}
                                                    </DropdownToggle>
                                                    <DropdownMenu container="body">
                                                        {Object.keys(options_status).map((key, i) =>
                                                            <DropdownItem name="status" key={key} value={key} onClick={this.accountChange}>{options_status[key]}</DropdownItem>
                                                        )}
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </InputGroup>
                                        </div>
                                    </Row>
                                    <Row className="mb-0">
                                        <label className="col-xl-3 col-form-label pt-0">Sales Rep</label>
                                        <span className="col-xl-9 text-bold">
                                            <span className={(this.state.sales_rep_select != false ? "d-none" : "")}>
                                                {this.state.sales_rep_name}
                                                <Button size="sm" color="default" className="ml-1 far fa-edit" onClick={this.editRep}></Button>
                                            </span>
                                            <UncontrolledButtonDropdown size="xs" className={(this.state.sales_rep_select == false ? "d-none" : "")}>
                                                <DropdownToggle size="xs" color="secondary" caret>
                                                    {this.state.sales_rep_name}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.staff).map(function (sales_rep_id, key) {
                                                        if (this.state.staff[sales_rep_id].status == 1) {
                                                            return (
                                                                <DropdownItem key={sales_rep_id}
                                                                              onClick={() => this.changeRep(sales_rep_id)}>{this.state.staff[sales_rep_id].first_name} {this.state.staff[sales_rep_id].last_name}</DropdownItem>
                                                            )
                                                        }
                                                    }, this)}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </span>
                                    </Row>
                                    <Row className={(this.state.account.sales_rep_id > 0 ? "mb-0" : "d-none")}>
                                        <label className="col-xl-3 col-form-label pt-0">Call Frequency</label>
                                        <span className="col-xl-9 text-bold">
                                            <span className={(this.state.call_frequency_select != false ? "d-none" : "")}>
                                                {this.state.call_frequency_options[this.state.account.call_frequency]}
                                                <Button size="sm" color="default" className="ml-1 far fa-edit" onClick={this.editCallFrequency}></Button>
                                            </span>
                                            <UncontrolledButtonDropdown size="xs" className={(this.state.call_frequency_select == false ? "d-none" : "")}>
                                                <DropdownToggle size="xs" color="secondary" caret>
                                                    {this.state.call_frequency_options[this.state.account.call_frequency]}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.call_frequency_options).map(function (call_frequency) {
                                                        return (
                                                            <DropdownItem key={call_frequency}
                                                                          onClick={() => this.changeCallFrequency(call_frequency)}>{this.state.call_frequency_options[call_frequency]}</DropdownItem>
                                                        )
                                                    }, this)}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </span>
                                    </Row>
                                    <Row className="mb-1 border-top pt-1">
                                        <label className="col-xl-3 col-form-label pt-0">License #*</label>
                                        <div className="col-xl-9">
                                            <Input type="text" name="license_num" value={this.state.account.license_num} size="sm" required onChange={this.accountChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Account Name*</label>
                                        <div className="col-xl-9">
                                            <Input type="text" name="name" value={this.state.account.name} size="sm" required onChange={this.accountChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Banner</label>
                                        <div className="col-xl-9">
                                            <Input type="text" name="banner" value={this.state.account.banner} size="sm" onChange={this.accountChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Address 1*</label>
                                        <div className="col-xl-9">
                                            <Input type="text" name="address1" value={this.state.account.address1} size="sm" required onChange={this.accountChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Address 2</label>
                                        <div className="col-xl-9">
                                            <Input type="text" name="address2" value={this.state.account.address2} size="sm" onChange={this.accountChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">City*</label>
                                        <div className="col-xl-9">
                                            <Input type="text" name="city" value={this.state.account.city} size="sm" required onChange={this.accountChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Province*</label>
                                        <div className="col-xl-9">
                                            <Input type="text" name="province" value={this.state.account.province} size="sm" required onChange={this.accountChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Postal Code*</label>
                                        <div className="col-xl-9">
                                            <Input type="text" name="postal_code" value={this.state.account.postal_code} size="sm" required onChange={this.accountChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Phone</label>
                                        <div className="col-xl-9">
                                            <div className="input-group">
                                                <Input type="tel" className="form-control" name="phone" bsSize="10" size="sm" onChange={this.accountChange}
                                                       value={this.state.account.phone}/>
                                                <a href={"tel:" + this.state.account.phone} title="Call Number"
                                                   className={(this.state.account.phone != null ? "btn btn-sm btn-primary input-group-addon" : "d-none")}><i
                                                    className="fas fa-phone"></i></a>
                                            </div>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Website</label>
                                        <div className="col-xl-9">
                                            <Input type="url" name="website" value={this.state.account.website} size="sm" onChange={this.accountChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1 border-top pt-1">
                                        <label className="col-xl-3 col-form-label pt-0">Manager Name</label>
                                        <div className="col-xl-9">
                                            <Input type="text" name="manager_name" value={this.state.account.manager_name} size="sm" onChange={this.accountChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Manager Phone</label>
                                        <div className="col-xl-9">
                                            <div className="input-group">
                                                <Input type="tel" name="manager_phone" value={this.state.account.manager_phone} size="sm" onChange={this.accountChange}/>
                                                <a href={"tel:" + this.state.account.manager_phone} title="Call Number"
                                                   className={(this.state.account.manager_phone != null ? "btn btn-sm btn-primary input-group-addon" : "d-none")}><i
                                                    className="fas fa-phone"></i></a>
                                            </div>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Manager Email</label>
                                        <div className="col-xl-9">
                                            <div className="input-group">
                                                <Input type="email" name="manager_email" value={this.state.account.manager_email} size="sm" onChange={this.accountChange}/>
                                                <a href={"mailto:" + this.state.account.manager_email} title="Send Email"
                                                   className={(this.state.account.manager_email != null ? "btn btn-sm btn-primary input-group-addon" : "d-none")}><i
                                                    className="far fa-paper-plane"></i></a>
                                            </div>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">TNG Card Code</label>
                                        <div className="col-xl-9">
                                            <Input type="text" name="tng_card_code" value={this.state.account.tng_card_code} size="sm" onChange={this.accountChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1 border-top pt-1">
                                        <label className="col-xl-3 col-form-label pt-0">Delivery Instructions</label>
                                        <div className="col-xl-9">
                                            <Input type="text" name="delivery_instructions" value={this.state.account.delivery_instructions} size="sm"
                                                   onChange={this.accountChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Delivery Defaults</label>
                                        <div className="col-xl-9">
                                            <InputGroup>
                                                <UncontrolledButtonDropdown>
                                                    <DropdownToggle color="light" size="sm" caret>
                                                        Day: {this.state.account.delivery_day}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {Object.keys(this.state.delivery_days).map((key, i) =>
                                                            <DropdownItem key={key} onClick={() => this.changeDelivery("day", key)}>{this.state.delivery_days[key]}</DropdownItem>
                                                        )}
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                                <UncontrolledButtonDropdown>
                                                    <DropdownToggle color="light" size="sm" caret>
                                                        Week: {this.state.delivery_weeks[this.state.account.delivery_week]}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {Object.keys(this.state.delivery_weeks).map((key, i) =>
                                                            <DropdownItem key={key} onClick={() => this.changeDelivery("week", key)}>{this.state.delivery_weeks[key]}</DropdownItem>
                                                        )}
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                                <UncontrolledButtonDropdown>
                                                    <DropdownToggle color="light" size="sm" caret style={{width: 'auto'}}>
                                                        By: {(this.state.account.delivery_inventory_source_id == null ? "All" : (typeof this.state.inventory_sources[this.state.account.delivery_inventory_source_id] != "undefined" ? this.state.inventory_sources[this.state.account.delivery_inventory_source_id].name : ""))}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem key="null" onClick={() => this.changeInventory(null)}>All</DropdownItem>
                                                        <DropdownItem divider/>
                                                        {Object.keys(this.state.inventory_sources).map(function (inventory_source_id, key) {
                                                            return (
                                                                <DropdownItem key={inventory_source_id}
                                                                              onClick={() => this.changeDelivery("inventory", inventory_source_id)}>{this.state.inventory_sources[inventory_source_id].name}</DropdownItem>
                                                            )
                                                        }, this)}
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </InputGroup>
                                        </div>
                                    </Row>
                                    <Row className="mb-1 border-top">
                                        <label className="col-xl-3 col-form-label pt-0">Payment Type</label>
                                        <div className="col-xl-9">
                                            <Row>
                                                <Col md={6} lg={5}>
                                                    <InputGroup>
                                                        <UncontrolledButtonDropdown className="mt-1">
                                                            <DropdownToggle color="light" size="sm" caret
                                                                            disabled={([45, 1, 55, 60].includes(parseInt(localStorage.getItem('staff_id'))) == true ? false : true)}>
                                                                {(payment_types[this.state.account.payment_type])}
                                                            </DropdownToggle>
                                                            <DropdownMenu container="body">
                                                                {Object.keys(payment_types).map((key, i) =>
                                                                    <DropdownItem name="payment_type" key={key} value={key} onClick={this.accountChange}>{payment_types[key]}</DropdownItem>
                                                                )}
                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Row>
                                    <Row className="mb-1 border-top">
                                        <label className="col-xl-3 col-form-label pt-0">Credit Terms:</label>
                                        <div className="col-xl-9">
                                            <Row>
                                                <Col md={6} lg={7}>
                                                    <InputGroup className="mt-1">
                                                        <InputGroupText className="pr-1" style={{"borderRadius": "5px 0px 0px 5px"}}>
                                                            Terms (Days):
                                                        </InputGroupText>
                                                        <Input className="text-right"
                                                               disabled={((localStorage.getItem('permissions') == "ALL" || localStorage.getItem('permissions').indexOf(',CL,') != -1) ? false : true)}
                                                               style={{"borderLeft": "0px !important"}} type="number" name="terms"
                                                               value={this.state.account.terms} onChange={this.accountChange}/>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6} lg={7}>
                                                    <InputGroup className={"mt-1"}>
                                                        <InputGroupText className="pr-1" style={{"borderRadius": "5px 0px 0px 5px"}}>
                                                            Limit*: $
                                                        </InputGroupText>
                                                        <Input className="text-right"
                                                               disabled={((localStorage.getItem('permissions') == "ALL" || localStorage.getItem('permissions').indexOf(',CL,') != -1) ? false : true)}
                                                               style={{"borderLeft": "0px !important"}} type="number" name="credit_limit"
                                                               value={this.state.account.credit_limit} onChange={this.accountChange}/>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                            <small className="text-muted">* Not applicable for credit cards. Orders exceeding the credit limit are reviewed before being sent for delivery</small>
                                        </div>
                                    </Row>
                                    <Row className="mb-1 border-top">
                                        <label className="col-xl-3 col-form-label pt-0">Internal Notes</label>
                                        <div className="col-xl-9">
                                            <Input type="textarea" style={{minHeight: "100px"}} name="notes" value={this.state.account.notes} onChange={this.accountChange}/>
                                        </div>
                                    </Row>
                                    <Row className="">
                                        <div className="offset-xl-3 col-xl-9">
                                            <button className="btn btn-primary" type="submit">Save Changes</button>
                                        </div>
                                    </Row>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Card className={(this.state.summary.loading?"card card-default whirl traditional":"card card-default")}>
                            <CardBody>
                                <p className="lead bb">Stats</p>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Opened</label>
                                    <div className="col-xl-8">
                                        <div className="text-bold">{this.state.summary.date_opened}</div>
                                    </div>
                                </Row>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Next Delivery Date</label>
                                    <div className="col-xl-8">
                                        <div className="text-bold">{getDeliveryDate(this.state.account.delivery_day, this.state.account.delivery_week, "human_date")}</div>
                                    </div>
                                </Row>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Since Last Order</label>
                                    <div className="col-xl-8">
                                        <div
                                            className={((this.state.avg_order_duration != null && this.state.summary.since_last_order > (this.state.summary.avg_order_duration)) && this.state.summary.num_orders > 1 ? 'text-bold text-danger' : 'text-bold')}>{this.state.summary.since_last_order} Days
                                        </div>
                                    </div>
                                </Row>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Avg. Order Frequency</label>
                                    <div className="col-xl-8">
                                        <div className="text-bold">{this.state.summary.avg_order_duration} Days</div>
                                    </div>
                                </Row>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label"># Orders</label>
                                    <div className="col-xl-8">
                                        <div className="text-bold">{this.state.summary.num_orders}</div>
                                    </div>
                                </Row>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Average Order</label>
                                    <div className="col-xl-8">
                                        <div className="text-bold">${(this.state.summary.total_spent / this.state.summary.num_orders).toFixed(2)}</div>
                                    </div>
                                </Row>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Total Revenue</label>
                                    <div className="col-xl-8">
                                        <div className="text-bold">${this.state.summary.total_spent.toLocaleString("en-US")}</div>
                                    </div>
                                </Row>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Since Last Call</label>
                                    <div className="col-xl-8">
                                        <div className="text-bold">{(this.state.summary.last_visited > 0 ? this.state.summary.last_visited + " Days Ago" : "Never Visited")}</div>
                                    </div>
                                </Row>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Credit Balance</label>
                                    <div className="col-xl-8">
                                        <span className="text-bold">${parseFloat(this.state.account.credit.amount).toFixed(2)}</span>
                                        <button className={(this.state.account.credit.details.length > 0 ? "ml-1 btn btn-primary btn-xs" : "d-none")}
                                                onClick={() => this.credit_history_toggle()}><i class="fas fa-history"></i> History
                                        </button>
                                        <button
                                            className={((localStorage.getItem('permissions') == "ALL" || localStorage.getItem('permissions').indexOf(',AAC,') != -1) ? "ml-1 btn btn-primary btn-xs" : "d-none")}
                                            onClick={() => this.credit_add_toggle()}><i className="fas fa-plus"></i> Credit
                                        </button>
                                    </div>
                                </Row>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">AR Balance</label>
                                    <div className="col-xl-8">
                                        <div className="text-bold">${money.format("USD", this.state.orders.total_owing)}</div>
                                    </div>
                                </Row>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Marketing Spend</label>
                                    <div className="col-xl-8">
                                        <div className="text-bold">${money.format("USD", this.state.spends.total)}</div>
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                        <ManageCard account_id={this.props.match.params.account_id}/>
                        <Card className={(this.state.users.loading?"card card-default whirl traditional":"card card-default")}>
                            <CardBody>
                                <div className="lead bb">
                                    {this.state.users.list.length} User{(this.state.users.list.length > 1 ? 's' : '')}
                                    <div className="float-right">
                                        <AddUser account_id={this.props.match.params.account_id} account_name={this.state.account.name} loadUsers={this.loadUsers}/>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className={(this.state.users.list.length > 0 && !this.state.orders.loading ? "table" : "d-none")}>
                                        <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th></th>
                                            <th>Email</th>
                                            <th>Tel</th>
                                            <th>Title</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.users.list.map(function (user, key) {
                                            return (
                                                <tr className={(user.status != 1 ? 'text-muted' : '')}>
                                                    <td><EditUser first_name={user.first_name} last_name={user.last_name} account_id={this.state.account_id} user_id={user.user_id}
                                                                  loadUsers={this.loadUsers}/></td>
                                                    <td>
                                                        <button className={(localStorage.getItem('role_id') == 1 ? "btn btn-xs btn-primary" : "d-none")}
                                                                onClick={() => this.adminLogin(user.user_id)}><i className="fas fa-sign-in-alt" title="Login"></i> Login
                                                        </button>
                                                    </td>
                                                    <td>{user.email}</td>
                                                    <td>{user.tel}</td>
                                                    <td>{user.title}</td>
                                                </tr>
                                            )
                                        }, this)}
                                        </tbody>
                                    </table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Card className={((this.state.account.account_type == 3 || this.state.account.account_type == 8) ? "card card-default" : "d-none")}>
                    <CardBody>
                        <p className="lead bb">Inventory Trend <span className="text-muted small">(Selling Units)</span></p>
                        <InventoryChart account_id={this.props.match.params.account_id} account_type={this.state.account.account_type}></InventoryChart>
                    </CardBody>
                </Card>
                <Card className={(this.state.orders.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <CardBody>
                        <p className="lead bb">{this.state.orders.list.length} Orders</p>
                        <div className="table-responsive">
                            <table className={(this.state.orders.list.length > 0 && !this.state.orders.loading ? "table table-hover table-pointer text-right" : "d-none")}>
                                <thead>
                                <tr>
                                    <th className="d-none d-lg-table-cell">#</th>
                                    <th className="d-none d-md-table-cell">Kegs</th>
                                    <th className="d-none d-md-table-cell">Cases</th>
                                    <th className="d-none d-lg-table-cell">Subtotal</th>
                                    <th className="d-none d-lg-table-cell">Tax</th>
                                    <th className="d-none d-lg-table-cell">Deposit</th>
                                    <th>Charge</th>
                                    <th>Payable</th>
                                    <th className="d-none d-sm-table-cell text-left">Del. Method</th>
                                    <th className="d-none d-sm-table-cell text-left">Pay. Method</th>
                                    <th className="text-left">Date/Time</th>
                                    <th className="d-none d-sm-table-cell">Pay. Status</th>
                                    <th className="d-none d-sm-table-cell">Del. Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr className="font-weight-bold bg-gray-lighter">
                                    <td className="d-none d-lg-table-cell">Total <small>(paid & receivable)</small>:</td>
                                    <td className="d-none d-md-table-cell">{this.state.orders.kegs}</td>
                                    <td className="d-none d-md-table-cell">{this.state.orders.cases}</td>
                                    <td className="d-none d-lg-table-cell">${this.state.orders.subtotal}</td>
                                    <td className="d-none d-lg-table-cell">${this.state.orders.tax}</td>
                                    <td className="d-none d-lg-table-cell">${this.state.orders.deposit}</td>
                                    <td>${this.state.orders.total}</td>
                                    <td>{money.format("USD", this.state.orders.total_owing)}</td>
                                    <td className="d-none d-sm-table-cell text-left"></td>
                                    <td className="d-none d-sm-table-cell text-left"></td>
                                    <td></td>
                                    <td className="d-none d-sm-table-cell"></td>
                                    <td className="d-none d-sm-table-cell"></td>
                                </tr>
                                {this.state.orders.list.map(function(item, key) {
                                    if (item.order_id>0) {
                                        return (
                                            <tr key={key} onClick={this.goToOrder.bind(this, item.order_id)}
                                                style={{"text-decoration": (item.is_paid === null || item.void_date != null ? "line-through" : "none")}}>
                                                <td className="d-none d-lg-table-cell">{item.order_id}</td>
                                                <td className="d-none d-md-table-cell">{(item.num_30l + item.num_50l)}</td>
                                                <td className="d-none d-md-table-cell">{(item.num_355ml + item.num_473ml)}</td>
                                                <td className="d-none d-lg-table-cell">${item.subtotal.toFixed(2)}</td>
                                                <td className="d-none d-lg-table-cell">${item.tax.toFixed(2)}</td>
                                                <td className="d-none d-lg-table-cell">${item.deposit.toFixed(2)}</td>
                                                <td>${item.total.toFixed(2)}</td>
                                                <td>${((item.is_paid == 5 || item.void_date != null) ? 0 : (item.total - item.paid)).toFixed(2)}</td>
                                                <td className="d-none d-sm-table-cell text-left">{(item.inventory_source_id == 0 || typeof this.state.inventory_sources[item.inventory_source_id] == "undefined" ? "--" : this.state.inventory_sources[item.inventory_source_id].name)}</td>
                                                <td className="d-none d-sm-table-cell text-left">{(item.payment_method == 1 ? "Credit Card" : (item.payment_method ==3 ? "EFT" : (item.payment_method == 6 ? "E-Transfer" : "Cheque")))}</td>
                                                <td className="text-left">{item.date_short}</td>
                                                <td className="d-none d-sm-table-cell">
                                                    <PaymentStatus is_paid={item.is_paid} void_date={item.void_date} company_id={item.company_id}/>
                                                </td>
                                                <td className="d-none d-sm-table-cell">
                                                    <DeliveryStatus shipping_status={item.shipping_status} company_id={item.company_id}/>
                                                </td>
                                            </tr>
                                        )
                                    }
                                },this)}
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>
                <Card style={(this.state.account.account_type == 3 ? {display: "none"} : {})}
                      className={(this.state.bols.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <CardBody>
                        <div className="lead bb">
                            {this.state.bols.list.length} BOLs
                            <div className="float-right">
                                <Button size="xs" color="primary" className="mb-2" onClick={this.createBOL}><i className="fas fa-plus"></i> BOL</Button>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className={(this.state.bols.list.length > 0 && !this.state.loading ? "table table-hover table-pointer text-right" : "d-none")}>
                                <thead>
                                <tr>
                                    <th className="d-none d-lg-table-cell">BOL #</th>
                                    <th className="d-none d-md-table-cell">Kegs</th>
                                    <th className="d-none d-md-table-cell">Cases</th>
                                    <th className="d-none d-sm-table-cell text-left">Del. Method</th>
                                    <th className="text-left">Date/Time</th>
                                    <th className="d-none d-sm-table-cell">Del. Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.bols.list.map(function (item, key) {
                                    return (
                                        <tr key={key} onClick={this.goToBOL.bind(this, item.bol_id)}
                                            style={{"text-decoration": (item.void_date != null ? "line-through" : "none")}}>
                                            <td className="d-none d-lg-table-cell">{item.bol_id}</td>
                                            <td className="d-none d-md-table-cell">{item.kegs}</td>
                                            <td className="d-none d-md-table-cell">{item.cases}</td>
                                            <td className="d-none d-sm-table-cell text-left">{(item.inventory_source_id == 0 || typeof this.state.inventory_sources[item.inventory_source_id] == "undefined" ? "--" : this.state.inventory_sources[item.inventory_source_id].name)}</td>
                                            <td className="text-left">{item.date_short}</td>
                                            <td className="d-none d-sm-table-cell">
                                                <DeliveryStatus shipping_status={item.shipping_status} void_date={item.void_date} company_id={item.company_id}/>
                                            </td>
                                        </tr>
                                    )
                                }, this)}
                                </tbody>
                                <tfoot>
                                <tr className="font-weight-bold bg-gray-lighter">
                                    <td className="d-none d-lg-table-cell text-right">Total:</td>
                                    <td className="d-none d-md-table-cell">{this.state.bols.kegs}</td>
                                    <td className="d-none d-md-table-cell">{this.state.bols.cases}</td>
                                    <td className="d-none d-sm-table-cell text-left"></td>
                                    <td></td>
                                    <td className="d-none d-sm-table-cell"></td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </CardBody>
                </Card>

                <CallNotes account_id={this.props.match.params.account_id} account={this.state.account} staff={this.state.staff}
                           selected_call_id={this.props.match.params.call_id}/>

                <Card className={(this.state.spends.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <CardBody>
                        <div className="lead bb">
                            {this.state.bols.list.length} Spends
                            <div className="float-right">
                                <Button size="xs" color="primary" className="mb-2" onClick={() => this.spend_toggle()}><i className="fas fa-plus"></i> Spend</Button>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className={(this.state.spends.list.length > 0 && !this.state.loading ? "table table-hover table-pointer" : "d-none")}>
                                <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th className="text-right">Amount</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.spends.list.map(function (item, key) {
                                    return (
                                        <tr key={key}>
                                            <td>{moment(item.spend_date).format("YYYY-MM-DD HH:mm:ss")}</td>
                                            <td>{item.description}</td>
                                            <td className="text-right">${money.format("USD", money.floatToAmount(item.amount))}</td>
                                        </tr>
                                    )
                                }, this)}
                                </tbody>
                                <tfoot>
                                <tr className="font-weight-bold bg-gray-lighter">
                                    <td className="text-right" colspan="2">Total:</td>
                                    <td className="text-right">${money.format("USD", this.state.spends.total)}</td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </CardBody>
                </Card>

                <Modal isOpen={this.state.credit_history_modal} toggle={this.credit_history_toggle} size="lg">
                    <ModalHeader>
                        <div className="h3 mb-0">Credit History</div>
                    </ModalHeader>
                    <ModalBody>
                        <table className="table table-sm table-hover table-pointer">
                            <thead>
                            <tr>
                                <th className="d-none d-lg-table-cell">Date</th>
                                <th className="d-none d-md-table-cell">Amount</th>
                                <th className="d-none d-md-table-cell">Category</th>
                                <th className="d-none d-lg-table-cell">Details</th>
                                <th className="d-none d-lg-table-cell">Notes</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.account.credit.details.map(function (item, key) {
                                return (
                                    <tr key={key}>
                                        <td>{moment(item.date).format('YYYY-MM-DD')}</td>
                                        <td className="text-right">${item.amount.toFixed(2)}</td>
                                        <td>{item.type}</td>
                                        <td>{item.details}</td>
                                        <td>{item.notes}</td>
                                    </tr>
                                )
                            }, this)}
                            </tbody>
                            <tfoot>
                            <tr>
                                <th>TOTAL:</th>
                                <th className="text-right">${parseFloat(this.state.account.credit.amount).toFixed(2)}</th>
                                <th>Credit Balance</th>
                                <th></th>
                                <th></th>
                            </tr>
                            </tfoot>
                        </table>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.credit_add_modal} toggle={this.credit_add_toggle} size="md">
                    <ModalHeader>
                        <div className="h3 mb-0">Add Account Credit</div>
                    </ModalHeader>
                    <ModalBody>
                        <div className="form-horizontal">
                            <Row className="mb-2">
                                <label className="col-xl-3 col-form-label pt-0">Reason</label>
                                <div className="col-xl-9">
                                    <Input type="text" name="credit_add_reason" value={this.state.credit_add_reason} onChange={this.updateField}/>

                                </div>
                            </Row>
                            <Row className="mb-0">
                                <label className="col-xl-3 col-form-label pt-0">Amount</label>
                                <div className="col-xl-9">
                                    <InputGroup className="mb-2">
                                        <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                        <Input type="text" name="credit_add_amount" value={this.state.credit_add_amount} onChange={this.updateField} required/>
                                    </InputGroup>
                                </div>
                            </Row>
                            <button className="btn btn-primary" type="submit" onClick={this.saveAccountCredit}>Add Credit</button>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.spend_modal} toggle={this.spend_toggle} size="md">
                    <ModalHeader>
                        <div className="h3 mb-0">Add Spend</div>
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup row>
                                <Label sm={4}>Date</Label>
                                <Col sm={8}>
                                    <DateRangePicker
                                        singleDatePicker={true}
                                        date={(this.state.spend_date == null ? moment() : this.state.spend_date)}
                                        onEvent={this.updateDates}
                                        autoUpdateInput={true}
                                        name="delivery_date"
                                    >
                                        <div className="btn btn-secondary">
                                            {this.state.spend_date.format('MMM. D, YYYY')}
                                            <span className="fas fa-caret-down fa-sm"></span>
                                        </div>
                                    </DateRangePicker>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Description</Label>
                                <Col sm={8}>
                                    <Input type="text" name="spend_description" value={this.state.spend_description}
                                           onChange={this.updateField}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Amount</Label>
                                <Col sm={8}>
                                    <InputGroup className="mb-2">
                                        <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                        <Input type="text" name="spend_amount" value={this.state.spend_amount} onChange={this.updateField} required/>
                                    </InputGroup>
                                </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <div className="btn btn-primary" onClick={() => this.addSpend()}>
                            Add Spend
                        </div>
                    </ModalFooter>
                </Modal>
                <ToastContainer/>
            </ContentWrapper>
        );
    }

}

export default AccountPage;

class InventoryChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "data": []
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.account_type !== this.props.account_type) {
            var self = this;
            axios.get(API_ROOT + '/inventory/' + (this.props.account_type == 3 ? 'lcbo' : 'nslc') + '/' + this.props.account_id)
                .then(function (response) {
                    self.setState({
                        data: response.data,
                    });
                })
                .catch(function (error) {
                });
        }
    }

    render() {
        return (
            <div>
                <div className={(this.state.data.length == 0 ? "text-danger" : "d-none")}>
                    No listings
                </div>
                <div style={{width: "100%", height: "200px"}} className={(this.state.data.length > 0 ? "" : "d-none")}>
                    <ResponsiveContainer>
                        <LineChart
                            width={500}
                            height={200}
                            data={this.state.data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="name"/>
                            <YAxis/>
                            <Tooltip/>
                            <Legend/>
                            <Line type="monotone" dataKey="Bees Knees" label="Bee's Knees" stroke="#e2498a" activeDot={{r: 5}}/>
                            <Line type="monotone" dataKey="French 75" label="French 75" stroke="#ffd5c0" activeDot={{r: 5}}/>
                            <Line type="monotone" dataKey="BevOps" label="BevOps" stroke="#001999" activeDot={{r: 5}}/>
                            <Line type="monotone" dataKey="Gin Mixer" label="Gin Mixer" stroke="#000000" activeDot={{r: 5}}/>
                            <Line type="monotone" dataKey="Alida" label="Alida" stroke="#339933" activeDot={{r: 5}}/>
                            <Line type="monotone" dataKey="SB Lemon Lime" label="S Lemon Lime" stroke="#e15d03" activeDot={{r: 5}}/>
                            <Line type="monotone" dataKey="SB Boysenberry" label="SB Boysenberry" stroke="#1E90FF" activeDot={{r: 5}}/>
                            <Line type="monotone" dataKey="SB Pink GF" label="SB Pink GF" stroke="#c90076" activeDot={{r: 5}}/>
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        );
    }
}
