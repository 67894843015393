import React, {Component} from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Button, Row, Col, Form, FormGroup, Label, Input, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import {getInventorySources, getProducts} from '../Inventory/InventoryFunctions.js';
import 'bootstrap/dist/css/bootstrap.css';

class CreatePickup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            products: {},
            products_sort: [],
            notes: '',
            loading: true,
            processing: false,
            inventory_levels: {},
            inventory_sources: [],
            inventory_source_id: 0,
            destination_id: 0,
            inventory_source_print: ""
        };
    }

    componentDidMount() {
        document.title = "Create Pickup | BevOps";
        var self = this;
        axios.defaults.withCredentials = true;
        getInventorySources(null, function (inventory_sources) {
            Object.keys(inventory_sources).map(function (destination_id, key) {
                if (inventory_sources[destination_id].staff_id == localStorage.getItem('staff_id')) {
                    self.setState({
                        destination_id: destination_id,
                    });
                }
            })
            inventory_sources[0] = {
                'name': '---',
            };
            getProducts(function (products) {
                var products_sort = [];
                for (const product_id of Object.keys(products)) {
                    products[product_id].ordered = 0
                    products_sort.push({id: product_id, name: products[product_id].name})
                }
                products_sort = products_sort.sort((a, b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
                axios.get(API_ROOT + '/inventory/overview/null/undefined')
                    .then(function (response) {
                        self.setState({
                            inventory_levels: response.data,
                            inventory_sources: inventory_sources,
                            inventory_source_print: "[Please Select]]",
                            products: products,
                            products_sort: products_sort,
                            loading: false
                        });
                    })
                    .catch(function (error) {
                        if (error.response.status === 401) {
                            self.props.userSignOut()
                        } else {
                            Swal("Error", error.response.data.Message, "error");
                        }
                    });
            });
        });
    }

    textChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    // Changing inventory source
    changeInventorySource = (inventory_source_id) => {
        var params = {
            inventory_source_id: inventory_source_id,
            inventory_source_print: this.state.inventory_sources[inventory_source_id].name,
        };
        this.setState(params);
    };

    // Add/Remove/Eit Items
    IncrementItem = (key) => {
        var old_products_list = this.state.products;
        old_products_list[key].ordered = old_products_list[key].ordered + 1;
        this.setState({products: old_products_list});
    };
    DecreaseItem = (key) => {
        var old_products_list = this.state.products;
        old_products_list[key].ordered = old_products_list[key].ordered - 1;
        this.setState({products: old_products_list});
    };
    ChangeItem = (key, event) => {
        var old_products_list = this.state.products;
        if (!isNaN(event.target.value)) {
            old_products_list[key].ordered = event.target.value;
            this.setState({products: old_products_list});
        }
    };

    submitOrder = async event => {
        if (this.state.inventory_source_id == 0) {
            Swal("Error", "Please select the inventory source", "error");
        } else {
            event.preventDefault()
            this.setState({
                processing: true
            });
            var self = this;
            axios.defaults.withCredentials = true;
            axios.post(API_ROOT + '/pickup/create', self.state)
                .then(function (response) {
                    localStorage.setItem('show_pickup_success' + response.data.pickup_id, true);
                    self.props.history.push("/pickup/" + response.data.pickup_id);
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                        self.setState({
                            processing: false
                        });
                    }
                });
        }
    };

    handleFocus = function (event) {
        if (event.target.value == "0") {
            event.target.value = "";
        }
        event.target.select();
    };

    render() {
        var self = this;
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <Link to="/pickup">Product Pickup Requests</Link>&nbsp;/ Create
                </div>
                <div className={(this.state.destination_id == 0 && !this.state.loading ? "alert alert-danger text-center" : "d-none")}>
                    You are not set up as an inventory source yet. Please request that inventory handling be activated to your account, which can be done in the Staff Manager
                    section.
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : (this.state.destination_id == 0 ? "d-none" : "card card-default"))}>
                    <div className="card-body">
                        <Form>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label className="font-weight-bold mb-0">Source</Label>
                                        <div>
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle color="secondary" caret size="sm">
                                                    {this.state.inventory_source_print}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.inventory_sources).map(function (inventory_source_id, key) {
                                                        if (self.state.inventory_sources[inventory_source_id].staff_id == null) {
                                                            return (
                                                                <DropdownItem key={inventory_source_id}
                                                                              onClick={() => this.changeInventorySource(inventory_source_id)}>{this.state.inventory_sources[inventory_source_id].name}</DropdownItem>
                                                            )
                                                        }
                                                    }, this)}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label className="font-weight-bold mb-0">Requestor</Label>
                                        <div>
                                            {localStorage.getItem('first_name')} {localStorage.getItem('last_name')}
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label className="font-weight-bold mb-0">Explanation</Label>
                                        <Input type="textarea" id="notes" onChange={this.textChange} value={this.state.notes}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                <tr className="bg-gray-lighter">
                                    <th>Product</th>
                                    <th>Inventory</th>
                                    <th>Quantity</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.products_sort.map(function (product, key) {
                                    var product_id = product.id;
                                    var inventory = 0;
                                    if (typeof this.state.inventory_levels[this.state.inventory_source_id] != "undefined" && typeof this.state.inventory_levels[this.state.inventory_source_id][product_id] != "undefined") {
                                        inventory = this.state.inventory_levels[this.state.inventory_source_id][product_id].total;
                                    }

                                    return (
                                        <tr key={product_id} className={(this.state.products[product_id].ordered > 0 ? "bg-info-light" : "")}>
                                            <td>
                                                <img src={"https://order.bevvy.ca/img/products/" + product_id + "_sm.png"} style={{width: "50px", height: "50px"}}
                                                     className="mr-1"></img>
                                                {this.state.products[product_id].name}
                                            </td>
                                            <td className={(inventory > 0 ? "" : "text-danger")}>
                                                {inventory}
                                            </td>
                                            <td>
                                                <div className="input-group mb-1">
                                                    <div className="input-group-prepend d-none d-sm-block" style={{cursor: "pointer"}}
                                                         onClick={this.DecreaseItem.bind(this, product_id)}>
                                                        <span className="input-group-text font-weight-bold" id="inputGroup-sizing-sm">-</span>
                                                    </div>
                                                    <input type="tel" className="form-control" value={this.state.products[product_id].ordered} onFocus={this.handleFocus}
                                                           onChange={this.ChangeItem.bind(this, product_id)} style={{maxWidth: '60px'}}></input>
                                                    <div className="input-group-append d-none d-sm-block" style={{cursor: "pointer"}}
                                                         onClick={this.IncrementItem.bind(this, product_id)}>
                                                        <span className="input-group-text font-weight-bold" id="inputGroup-sizing-sm">+</span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }, this)}
                                </tbody>
                            </table>
                            <div className="text-center">
                                <Button size="lg" color="primary" className="ml-4 mt-3" disabled={this.state.processing} onClick={this.submitOrder}><i
                                    className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>Create Pickup Request</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (CreatePickup);
