import React, {Component} from 'react';
import {
    Button,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    DropdownMenu,
    Form,
    FormGroup,
    DropdownItem,
    DropdownToggle,
    UncontrolledButtonDropdown,
    Label,
    Input
} from 'reactstrap';

import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import 'lity/dist/lity.min.js';
import 'lity/dist/lity.min.css';

class AddCallNotes extends React.Component {
    constructor(props) {
        super(props);
        window.AddCallNotes = this;
        this.addPhoto = this.addPhoto.bind(this);

        this.state = {
            modal: false,
            start1: moment().subtract(30, 'm').toDate(),
            end1: new Date(),
            notes: "",
            responses: {},
            reminders: [],
            call_id: false,
            photos: [],
            editable: true,
            processing: false
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.trigger_note !== this.props.trigger_note && !isNaN(this.props.trigger_note)) {
            var state = {
                modal: true,
                start1: moment().subtract(30, 'm').toDate(),
                end1: new Date(),
                notes: "",
                responses: {},
                reminders: [],
                editable: true,
                processing: false,
                call_id: this.props.trigger_note
            }
            this.setState(state);
            var self = this;
            axios.get(API_ROOT + '/account/' + this.props.account_id + '/call/' + this.props.trigger_note)
                .then(function (response) {
                    state['start1'] = moment(response.data['start']).toDate();
                    state['end1'] = moment(response.data['end']).toDate();
                    state['notes'] = response.data['notes'];
                    state['photos'] = response.data['photos'];
                    state['editable'] = response.data['editable'];
                    if (response.data['data'] != "" && response.data['data'] != null) {
                        state['responses'] = JSON.parse(response.data['data']);
                    } else {
                        state['responses'] = [];
                    }
                    state['processing'] = false;
                    var reminders = []
                    for (var i in response.data['reminders']) {
                        var reminder = response.data['reminders'][i];
                        reminders.push({
                            title: reminder['title'],
                            completed: reminder['completed'],
                            date: moment(reminder['date']).toDate(),
                        })
                    }
                    state['reminders'] = reminders;
                    self.setState(state);
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                        self.setState({
                            calendarEvents: [],
                            loading: false
                        });
                    }
                });
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    };

    changeDate = (key, date) => {
        this.setState({
            [key]: date
        });
    }

    addReminder = () => {
        var reminders = this.state.reminders;
        reminders.push({
            date: moment().add(1, 'd').toDate(),
            title: "",
            completed: 0
        });
        this.setState({
            reminders: reminders
        });
    };
    updateReminder = (reminder, type, data) => {
        var reminders = this.state.reminders;
        if (type == "delete") {
            reminders.splice(reminder)
        } else {
            reminders[reminder][type] = data;
        }
        this.setState({
            reminders: reminders
        });
    }

    changeDropdown = (question, brand, answer) => {
        var responses = this.state.responses;
        if (typeof responses[question] == "undefined") {
            responses[question] = {};
        }
        responses[question][brand] = answer;
        this.setState({
            responses: responses
        });
    }

    selectCallNotes = (name, value) => {
        if (typeof value != "string") {
            value = value.target.value;
        }
        this.setState({
            [name]: value
        });
    };

    addPhoto = (call_photo_id) => {
        var photos = this.state.photos;
        photos.push(call_photo_id);
        this.setState({
            photos: photos
        });
    }

    deleteCallNote = () => {
        var self = this;
        axios.defaults.withCredentials = true;
        Swal.fire({
            title: 'Are you sure?',
            text: "This note and all associated reminders will be deleted",
            showCancelButton: true,
            confirmButtonText: 'Yes, delete now',
            cancelButtonText: 'No',
        }).then((result) => {
            if (typeof result.dismiss == 'undefined') {
                axios.delete(API_ROOT + '/account/' + this.props.account_id + "/call_notes/" + this.state.call_id)
                    .then(function (response) {
                        self.setState({
                            modal: false,
                            call_id: false,
                            start1: moment().subtract(30, 'm').toDate(),
                            end1: new Date(),
                            notes: "",
                            responses: {},
                            reminders: [],
                            editable: true,
                            processing: false,
                        });
                        Swal("Done", "Call Note Deleted", "success");
                        self.props.getCalls();
                    })
                    .catch(function (error) {
                        Swal("Error", error.response.data.Message, "error");
                    });
            }
        });
    };

    addCallNotes = (editable) => {
        var self = this;
        self.setState({
            processing: true
        });
        axios.defaults.withCredentials = true;
        let data = this.state;
        data['editable'] = editable;
        data['account_id'] = this.props.account_id;
        data['staff_id'] = localStorage.getItem('staff_id')
        data['start'] = moment.utc(this.state.start1).format("YYYY-MM-DD HH:mm:ss");
        data['end'] = moment.utc(this.state.end1).format("YYYY-MM-DD HH:mm:ss");
        if (this.state.call_id > 0) {
            // edit it
            axios.put(API_ROOT + '/account/' + this.props.account_id + '/call_notes/' + this.state.call_id, data)
                .then(function (response) {
                    self.setState({
                        modal: false,
                        call_id: false,
                        start1: moment().subtract(30, 'm').toDate(),
                        end1: new Date(),
                        notes: "",
                        responses: {},
                        reminders: [],
                        editable: true,
                        processing: false,
                    });
                    Swal("Success!", "Call Note Saved", "success");
                    self.props.getCalls();
                })
                .catch(function (error) {
                    if (typeof error.response != "undefined") {
                        if (error.response.status === 401) {
                            self.props.userSignOut()
                        } else {
                            Swal("Error", error.response.data.Message, "error");
                            self.setState({
                                processing: false
                            });
                        }
                    }
                });
        } else {
            // add it
            axios.post(API_ROOT + '/account/' + this.props.account_id + '/call_notes', data)
                .then(function (response) {
                    self.setState({
                        modal: false,
                        call_id: false
                    });
                    Swal("Success!", "Call Note Added", "success");
                    self.props.getCalls();
                })
                .catch(function (error) {
                    if (typeof error.response != "undefined") {
                        if (error.response.status === 401) {
                            self.props.userSignOut()
                        } else {
                            Swal("Error", error.response.data.Message, "error");
                            self.setState({
                                processing: false
                            });
                        }
                    }
                });
        }
    };

  render() {
      var brands = ['BevOps', 'Bees Knees', 'French75', 'Alida', 'SB Lemon Lime', 'SB Boys.', 'SB Grapefruit']
      var questions = {
          1: [ // on-premise
              {
                  title: "In the Drink Menu?",
                  type: "option",
                  options: ["Yes", "No"]
              }, {
                  title: "In Ongoing Feature?",
                  type: "option",
                  options: ["Yes", "No"]
              }, {
                  title: "Proper Tap Handle?",
                  type: "option",
                  options: ["Yes", "No"]
              }, {
                  title: "Glassware",
                  type: "option",
                  options: ["Yes", "No"]
              }, {
                  title: "Visible in Fridge?",
                  type: "option",
                  options: ["Yes", "No"]
              }, {
                  title: "Any other POS (Posters/Tent Cards)?",
                  type: "option",
                  options: ["Yes", "No"]
              },
          ],
          2: [ // tbs
              {
                  title: "Product Location",
                  type: "option",
                  options: ["Warm", "Fridge"]
              }, {
                  title: "Shelf Placement",
                  type: "option",
                  options: ["Bottom", "Middle", "Top"]
              }, {
                  title: "# of Facings",
                  type: "option",
                  options: [4, 6, 8, 10, 12]
              }, {
                  title: "Listing Status",
                  type: "option",
                  options: ["Listed", "Delisted", "Force-Listed"]
              }, {
                  title: "Discretional Display?",
                  type: "option",
                  options: ["Yes", "No"]
              },
          ],
          3: [ // lcbo
              {
                  title: "Product Location",
                  type: "option",
                  options: ["Warm", "Fridge"]
              }, {
                  title: "Shelf Placement",
                  type: "option",
                  options: ["Bottom", "Middle", "Top"]
              }, {
                  title: "# of Facings",
                  type: "option",
                  options: [4, 6, 8, 10, 12]
              }, {
                  title: "Listing Status",
                  type: "option",
                  options: ["Listed", "Delisted", "Force-Listed"]
              }, {
                  title: "Discretional Display?",
                  type: "option",
                  options: ["Yes", "No"]
              },
          ],
          5: [ // grocery
              {
                  title: "Product Location",
                  type: "option",
                  options: ["Warm", "Fridge"]
              }, {
                  title: "Shelf Placement",
                  type: "option",
                  options: ["Bottom", "Middle", "Top"]
              }, {
                  title: "# of Facings",
                  type: "option",
                  options: [4, 6, 8, 10, 12]
              }, {
                  title: "Listing Status",
                  type: "option",
                  options: ["Listed", "Delisted", "Force-Listed"]
              }, {
                  title: "Discretional Display?",
                  type: "option",
                  options: ["Yes", "No"]
              },
          ],
          6: [ // agency
              {
                  title: "Product Location",
                  type: "option",
                  options: ["Warm", "Fridge"]
              }, {
                  title: "Shelf Placement",
                  type: "option",
                  options: ["Bottom", "Middle", "Top"]
              }, {
                  title: "# of Facings",
                  type: "option",
                  options: [4, 6, 8, 10, 12]
              }, {
                  title: "Listing Status",
                  type: "option",
                  options: ["Listed", "Delisted", "Force-Listed"]
              }, {
                  title: "Discretional Display?",
                  type: "option",
                  options: ["Yes", "No"]
              },
          ],
          7: [ // LCBO warehouse

          ],
          8: [ // NSLC
              {
                  title: "Product Location",
                  type: "option",
                  options: ["Warm", "Fridge"]
              }, {
                  title: "Shelf Placement",
                  type: "option",
                  options: ["Bottom", "Middle", "Top"]
              }, {
                  title: "# of Facings",
                  type: "option",
                  options: [4, 6, 8, 10, 12]
              }, {
                  title: "Listing Status",
                  type: "option",
                  options: ["Listed", "Delisted", "Force-Listed"]
              }, {
                  title: "Discretional Display?",
                  type: "option",
                  options: ["Yes", "No"]
              },
          ],
          10: [ // on-premise
              {
                  title: "In the Drink Menu?",
                  type: "option",
                  options: ["Yes", "No"]
              }, {
                  title: "In Ongoing Feature?",
                  type: "option",
                  options: ["Yes", "No"]
              }, {
                  title: "Proper Tap Handle?",
                  type: "option",
                  options: ["Yes", "No"]
              }, {
                  title: "Glassware",
                  type: "option",
                  options: ["Yes", "No"]
              }, {
                  title: "Visible in Fridge?",
                  type: "option",
                  options: ["Yes", "No"]
              }, {
                  title: "Any other POS (Posters/Tent Cards)?",
                  type: "option",
                  options: ["Yes", "No"]
              },
          ],
          11: [ // convenience
              {
                  title: "In the Drink Menu?",
                  type: "option",
                  options: ["Yes", "No"]
              }, {
                  title: "In Ongoing Feature?",
                  type: "option",
                  options: ["Yes", "No"]
              }, {
                  title: "Proper Tap Handle?",
                  type: "option",
                  options: ["Yes", "No"]
              }, {
                  title: "Glassware",
                  type: "option",
                  options: ["Yes", "No"]
              }, {
                  title: "Visible in Fridge?",
                  type: "option",
                  options: ["Yes", "No"]
              }, {
                  title: "Any other POS (Posters/Tent Cards)?",
                  type: "option",
                  options: ["Yes", "No"]
              },
          ]
      }

      return (
          <div className="float-right">
              <Button color="primary" size="xs" className="mb-2" onClick={this.toggle}><i className="fas fa-plus"></i> Call Notes</Button>
              <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg">
                  <ModalHeader>
                      <div className="h3 mb-0">Add Call Notes</div>
                  </ModalHeader>
                  <ModalBody>
                      <div className="text-bold h4 pb-0 mb-0 pt-2">Date & Time</div>
                      <div className="row">
                          <div className="col-md-6 text-center">
                              Start: <DatePicker
                              selected={this.state.start1}
                              wrapperClassName="border-left border-top border-right"
                              onChange={date => this.changeDate('start1', date)}
                              showTimeSelect
                              disabled={!this.state.editable}
                              dateFormat="MMMM d, yyyy h:mm aa"
                          />
                          </div>
                          <div className="col-md-6 text-center">
                              &nbsp;
                              End: <DatePicker
                              selected={this.state.end1}
                              wrapperClassName="border-left border-top border-right"
                              onChange={date => this.changeDate('end1', date)}
                              showTimeSelect
                              disabled={!this.state.editable}
                              dateFormat="MMMM d, yyyy h:mm aa"
                          />
                          </div>
                      </div>

                      <div className="text-bold h4 pb-0 mb-0 pt-2">Notes</div>
                      <Input type="textarea" name="notes" value={this.state.notes} disabled={!this.state.editable} onChange={(event) => this.selectCallNotes("notes", event)}/>
                      <div className="text-bold h4 pb-0 mb-0 pt-2">Observations</div>
                      <div style={{overflowX: "scroll"}}>
                          <table className="table table-bordered table-sm">
                              <thead>
                              <tr>
                                  <th></th>
                                  {brands.map(function (brand, key) {
                                      return (
                                          <th>{brand}</th>
                                      )
                                  }, this)}
                              </tr>
                              </thead>
                              <tbody>
                              {typeof this.props.account != "undefined" && typeof this.props.account.account_type != "undefined" && this.props.account.account_type > 0 && this.props.account.account_type != 4 && questions[this.props.account.account_type].map(function (question, key) {
                                  return (
                                      <tr>
                                          <td>{question.title}</td>
                                          {brands.map(function (brand) {
                                              var current = "--";
                                              if (typeof this.state.responses[question.title] != "undefined" && typeof this.state.responses[question.title][brand] != "undefined") {
                                                  current = this.state.responses[question.title][brand]
                                              }
                                              return (
                                                  <td>
                                                      <UncontrolledButtonDropdown className="m-0 w-100">
                                                          <DropdownToggle disabled={!this.state.editable} caret color="light" className="text-left" style={{width: '100%'}}>
                                                              {current}
                                                          </DropdownToggle>
                                                          <DropdownMenu>
                                                              <DropdownItem key="null" onClick={() => this.changeDropdown(question.title, brand, '--')}>--</DropdownItem>
                                                              <DropdownItem divider/>
                                                              {question.options.map((option) =>
                                                                  <DropdownItem key={brand + option}
                                                                                onClick={() => this.changeDropdown(question.title, brand, option)}>{option}</DropdownItem>
                                                              )}
                                                          </DropdownMenu>
                                                      </UncontrolledButtonDropdown>
                                                  </td>
                                              )
                                          }, this)}
                                      </tr>
                                  )
                              }, this)}
                              </tbody>
                          </table>
                      </div>
                      <div className="text-bold h4 pb-0 mb-0">Photos</div>
                      <div>
                          {this.state.photos.map(function (photo, key) {
                              return (
                                  <a href={"call_photos/" + this.state.photos[key] + ".jpg"} data-lity>
                                      <img src={"call_photos/" + this.state.photos[key] + "_sm.jpg"} className="pr-2 pb-2"></img>
                                  </a>
                              )
                          }, this)}
                          <Upload account_id={this.props.account_id} call_id={this.state.call_id} photos={this.state.photos} addPhoto={this.addPhoto} />
                      </div>

                      <div className="text-bold h4 pb-0 mb-0 pt-2">Reminders</div>
                      {this.state.reminders.map(function (reminder, key) {
                          return (
                              <div className="row pt-2">
                                  <div className="pl-3">
                                      <DatePicker
                                          selected={this.state.reminders[key].date}
                                          onChange={date => this.updateReminder(key, 'date', date)}
                                          showTimeSelect
                                          dateFormat="MMMM d, yyyy h:mm aa"
                                          wrapperClassName="border-left border-top border-right"
                                          popperModifiers={{
                                              preventOverflow: {
                                                  enabled: true,
                                              },
                                          }}
                                      />
                                  </div>
                                  <div className="col">
                                      <input type="text" placeholder="Reminder Details" className="form-control" value={reminder.title}
                                             onChange={(event) => this.updateReminder(key, "title", event.target.value)}/>
                                  </div>
                                  <div className="col-1">
                                      <input type="checkbox" className="form-check-label" value="1" title="Mark as Completed" checked={reminder.completed}
                                             onClick={(event) => this.updateReminder(key, "completed", !reminder.completed)}/>
                                  </div>
                                  <div className="col-2">
                                      <input type="button" className="btn btn-danger btn-sm" value="Delete" onClick={(event) => this.updateReminder(key, "delete", null)}/>
                                  </div>
                              </div>
                          )
                      }, this)}
                      <div className="btn btn-primary btn-sm" onClick={this.addReminder}>Add Reminder</div>
                  </ModalBody>
                  <ModalFooter>
                      <div className={(this.state.call_id > 0 ? "btn btn-danger mr-auto" : "d-none")} onClick={this.deleteCallNote}>Delete</div>
                      <div className={(this.state.editable ? "btn btn-warning" : "d-none")} onClick={() => this.addCallNotes(1)}><i
                          className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>Save as DRAFT
                      </div>
                      <div className="btn btn-success" onClick={() => this.addCallNotes(0)}><i className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>Save
                          as COMPLETE
                      </div>
                  </ModalFooter>
              </Modal>
          </div>
    );
  };
}

const Upload = (props) => {
    const getUploadParams = ({ file, meta }) => {
        const url = API_ROOT+'/account/'+props.account_id+'/call_notes/'+props.call_id+'/photo';
        const body = new FormData();
        body.append('image', file);
        return { url, body  }
    };

    const handleChangeStatus = ({ meta, remove, xhr }, status) => {
        if (status === 'done'){
            var response = JSON.parse(xhr.response);
            var call_photo_id = response.call_photo_id;
            props.addPhoto(call_photo_id)
        } else if (status === 'headers_received') {
          remove()
        } else if (status === 'aborted') {
          Swal("Error", "Upload Failed", "error");
        }
    };

    return (
        <React.Fragment>
            <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                multiple={false}
                canCancel={false}
                accept="image/*"
                withCredentials={true}
                inputContent={(files, extra) => (extra.reject ? 'JPG or PNG images only' : "Upload a Photo (drag n' drop or click)")}
                styles={{
                  inputLabel: {fontSize:"14px", fontWeight:"normal"},
                  dropzone: { height: 100, minHeight:75,  border:"1px dashed #ccc", },
                  dropzoneActive: { borderColor: 'green' },
                }}
            />
        </React.Fragment>
    )
};

export default (AddCallNotes);
