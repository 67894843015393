import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Button, InputGroup, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledButtonDropdown} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import DeliveryStatus from '../Orders/DeliveryStatus';
import {getInventorySources} from '../Inventory/InventoryFunctions.js';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';
import {getOwners} from "../Owners/OwnerFunctions";

moment.tz.setDefault("America/Toronto");

class BOLHistory extends React.Component {
    constructor(props) {
        super(props);

        this.updateDates = this.updateDates.bind(this);

        this.state = {
            orders: [],
            kegs: 0,
            cases: 0,
            loading: true,
            // default search settings
            payment_method: null,
            payment_status: null,
            inventory_source_id: null,
            delivery_status: null,
            void_date: null,
            startDate: moment().subtract(29, 'days'),
            endDate: moment().endOf('day'),
            order_type: 1,
            // default search filter names
            order_type_name: "Real",
            delivery_method_name: "All",
            delivery_statuses: {0: "Processing", 1: "Scheduled", 2: "Out for Delivery", 3: "Delivered", 9: "Held - Pending Approval"},
            order_types: {0: "Test", 1: "Real"},
            inventory_sources: [],
            ranges: {
                'Today': [moment(), moment()],
                'Last 7 Days': [moment().subtract(6, 'days'), moment().endOf('day')],
                'Last 30 Days': [moment().subtract(29, 'days'), moment().endOf('day')],
                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'All Time': [moment("01 Jun 2018"), moment().endOf('day')]
            },
            company: (localStorage.getItem('company') != null ? localStorage.getItem('company') : null),
            companies_name: (localStorage.getItem('company_name') != null ? localStorage.getItem('company_name') : "All"),
            companies: [],
        };
    }

    componentDidMount() {
        document.title = "Bill of Ladings | BevOps";
        var self = this;
        getInventorySources('can_deliver', function (inventory_sources) {
            getOwners(function (owners) {
                inventory_sources[0] = {
                    'name': '---',
                };
                self.setState({
                    inventory_sources: inventory_sources,
                    companies: owners,
                });
                self.getBOLs();
            });
        });
    }


    // on date range change
    updateDates(event, picker) {
        if (event.type == "apply") {
            this.setState({
                startDate: picker.startDate,
                endDate: picker.endDate
            }, () => {
                this.getBOLs()
            });
        }
    }

    changeCompany = (company_id) => {
        if (company_id == null) {
            var companies_name = "All"
        } else {
            var companies_name = this.state.companies[company_id].name
        }
        localStorage.setItem('company', company_id);
        localStorage.setItem('company_name', companies_name);
        this.setState({
            company: company_id,
            companies_name: companies_name
        }, () => {
            this.getBOLs()
        });
    }

    changeDeliveryMethod = (inventory_source_id) => {
        if (inventory_source_id == null) {
            var delivery_method_name = "All"
        } else {
            var delivery_method_name = this.state.inventory_sources[inventory_source_id].name
        }
        this.setState({
            inventory_source_id: inventory_source_id,
            delivery_method_name: delivery_method_name
        }, () => {
            this.getBOLs()
        });
    }

    changeDeliveryStatus = (delivery_status) => {
        if (delivery_status == null) {
            var delivery_status_name = "All"
        } else {
            var delivery_status_name = this.state.delivery_statuses[delivery_status]
        }
        this.setState({
            delivery_status: delivery_status,
            delivery_status_name: delivery_status_name
        }, () => {
            this.getBOLs()
        });
    };

    changeOrderType = (order_type) => {
        if (order_type == null) {
            var order_type_name = "All"
        } else {
            var order_type_name = this.state.order_types[order_type]
        }
        this.setState({
            order_type: order_type,
            order_type_name: order_type_name
        }, () => {
            this.getBOLs()
        });
    };

    goToBOL = (bol_id) => {
        this.props.history.push("/bol/" + bol_id);
    };

    refreshSearch = event => {
        this.getBOLs();
    };

    getBOLs() {
        this.setState({
            loading: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/bols/?shipping_status=' + self.state.delivery_status + "&inventory_source_id=" + self.state.inventory_source_id + "&startDate=" + self.state.startDate + "&endDate=" + self.state.endDate + "&order_type=" + self.state.order_type + "&company_id=" + self.state.company)
            .then(function (response) {
                var kegs = 0;
                var cases = 0;
                response.data.map(function (item, key) {
                    kegs += item.kegs;
                    cases += item.cases;
                });
                self.setState({
                    orders: response.data,
                    loading: false,
                    kegs: kegs,
                    cases: cases
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        orders: [],
                        loading: false
                    });
                }
            });
    }

    render() {

        // date range selector variables
        let start = this.state.startDate.format('MMM. D/YY');
        let end = this.state.endDate.format('MMM. D/YY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }

        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Bill of Ladings</div>
                    <div className="ml-auto">
                        <Button onClick={this.refreshSearch}><i className="fas fa-sync-alt"></i></Button>
                    </div>
                </div>
                <div className="mb-3 form-inline">
                    <DateRangePicker
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        maxDate={moment()}
                        ranges={this.state.ranges}
                        onEvent={this.updateDates}
                        autoUpdateInput={true}
                    >
                        <Button className="selected-date-range-btn" color="primary">
                            Period: <b>{label}</b> <span className="fas fa-caret-down fa-sm"></span>
                        </Button>
                    </DateRangePicker>
                    <InputGroup>
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color={(String(this.state.company) == "null" ? "primary" : "warning")} style={{fontSize: '13px', width: 'auto'}}>
                                Companies:: <b>{this.state.companies_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeCompany(null)}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.companies).map((key, i) => {
                                        if (localStorage.getItem('companies') == "ALL" || localStorage.getItem('companies').indexOf(',' + key + ',') != -1) {
                                            return (<DropdownItem key={key} onClick={() => this.changeCompany(key)}>{this.state.companies[key].name}</DropdownItem>)
                                        }
                                    }
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                    <InputGroup>
                        <UncontrolledButtonDropdown>
                            <DropdownToggle color="primary" caret style={{fontSize: '13px', width: 'auto'}}>
                                Delivery By: <b>{this.state.delivery_method_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeDeliveryMethod(null)}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.inventory_sources).map((inventory_source_id, key) =>
                                    <DropdownItem key={inventory_source_id}
                                                  onClick={() => this.changeDeliveryMethod(inventory_source_id)}>{this.state.inventory_sources[inventory_source_id].name}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                    <InputGroup>
                        <UncontrolledButtonDropdown>
                            <DropdownToggle color="primary" caret style={{fontSize: '13px', width: 'auto'}}>
                                Delivery Status: <b>{this.state.delivery_status_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeDeliveryStatus(null)}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.delivery_statuses).map((key, i) =>
                                    <DropdownItem key={key} onClick={() => this.changeDeliveryStatus(key)}>{this.state.delivery_statuses[key]}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                    <InputGroup>
                        <UncontrolledButtonDropdown>
                            <DropdownToggle color="primary" caret style={{fontSize: '13px', width: 'auto'}}>
                                Type: <b>{this.state.order_type_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeOrderType(null)}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.order_types).map((key, i) =>
                                    <DropdownItem key={key} onClick={() => this.changeOrderType(key)}>{this.state.order_types[key]}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div className={(this.state.loading ? "invisible" : "")}>
                        <div className="card-header">
                            <div className="card-title">{this.state.orders.length} Matching BOLs</div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className={(this.state.orders.length > 0 && !this.state.loading ? "table table-hover table-pointer text-right" : "d-none")}>
                                    <thead>
                                    <tr>
                                        <th className="d-none d-lg-table-cell">BOL #</th>
                                        <th className="d-none d-lg-table-cell">License #</th>
                                        <th className="text-left">Account</th>
                                        <th className="d-none d-md-table-cell">Kegs</th>
                                        <th className="d-none d-md-table-cell">Cases</th>
                                        <th className="d-none d-sm-table-cell text-left">Del. Method</th>
                                        <th className="text-left">Date/Time</th>
                                        <th className="d-none d-sm-table-cell">Del. Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.orders.map(function (item, key) {
                                        var inventory_name = "--"
                                        if (typeof this.state.inventory_sources[item.inventory_source_id] != "undefined") {
                                            inventory_name = this.state.inventory_sources[item.inventory_source_id].name
                                        }
                                        return (
                                            <tr key={key} onClick={this.goToBOL.bind(this, item.bol_id)}
                                                style={{"text-decoration": (item.void_date != null ? "line-through" : "none")}}>
                                                <td className="d-none d-lg-table-cell">{item.bol_id}</td>
                                                <td className="d-none d-lg-table-cell">{item.license_num}</td>
                                                <td className="text-left">{item.name}<br></br><small>{item.address1}, {item.city}</small></td>
                                                <td className="d-none d-md-table-cell">{item.kegs}</td>
                                                <td className="d-none d-md-table-cell">{item.cases}</td>
                                                <td className="d-none d-sm-table-cell text-left">{inventory_name}</td>
                                                <td className="text-left">{item.date_short}</td>
                                                <td className="d-none d-sm-table-cell">
                                                    <DeliveryStatus shipping_status={item.shipping_status} void_date={item.void_date}/>
                                                </td>
                                            </tr>
                                        )
                                    }, this)}
                                    </tbody>
                                    <tfoot>
                                    <tr className="font-weight-bold bg-gray-lighter">
                                        <td className="d-none d-lg-table-cell"></td>
                                        <td className="d-none d-lg-table-cell"></td>
                                        <td className="text-right">Total:</td>
                                        <td className="d-none d-md-table-cell">{this.state.kegs}</td>
                                        <td className="d-none d-md-table-cell">{this.state.cases}</td>
                                        <td className="d-none d-sm-table-cell text-left"></td>
                                        <td></td>
                                        <td className="d-none d-sm-table-cell"></td>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (BOLHistory);