import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {
    UncontrolledButtonDropdown,
    Button,
    Modal,
    Input,
    DropdownToggle,
    Col,
    Label,
    FormGroup,
    DropdownItem,
    DropdownMenu,
    InputGroup,
    Form,
    Alert,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import {getProductsParent} from '../Products/ProductFunctions.js';
import {getOwners} from '../Owners/OwnerFunctions.js';
import {getBrands} from '../Brands/BrandFunctions.js';
import {getTypes} from '../Types/Functions.js';
import {getFactors} from '../Common/Factors.js';

import {Link} from 'react-router-dom';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ProductsOverview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            products: {},
            products_sort: [],
            loading: true,
            // default product selection
            product: {
                name: "",
                description: "",
                owner_id: null,
                brand_id: null,
                type_id: null,
            },
            // display
            dropdown_print: {
                owner: "",
                brand: "",
                type: "",
            },
            // default lists
            list: {
                type: [],
                brand: [],
                owner: [],
                factor: [],
            },
            product_parent_id: null,
            modal: false,
            mode: ""
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        document.title = "Products | BevOps";
        var self = this;
        getOwners(function (owners) {
            var list = self.state.list;
            list.owner = owners;
            getBrands(function (brands) {
                list.brand = brands;
                getTypes(function (types) {
                    list.type = types;
                    getFactors(function (factor) {
                        list.factor = factor;
                        self.setState({list: list})
                        self.setState(
                            {list: list},
                            () => self.getProductParents()
                        );
                    })
                });
            });
        });
    }

    getProductParents() {
        var self = this;
        getProductsParent(function (products) {
            var products_sort = []
            Object.keys(products).map(function (product_parent_id, key) {
                products[product_parent_id].visibile = false;
                products_sort.push({id: product_parent_id, name: products[product_parent_id].name})
            });
            products_sort = products_sort.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
            self.setState({
                products_sort: products_sort,
                products: products,
                loading: false
            });
        });
    }

    toggleProductParent(key) {
        var products = this.state.products;
        products[key].visible = !products[key].visible;
        this.setState({
            products: products
        })
    }

    handleChange(event) {
        var product = this.state.product;
        product[event.target.id] = event.target.value
        this.setState({
            product: product
        });
    }

    changeDropdown = (value, key) => {
        var dropdown_print = this.state.dropdown_print;
        if (value == null) {
            dropdown_print[key] = ""
        } else {
            dropdown_print[key] = this.state.list[key][value].name;
        }
        var product = this.state.product;
        product[key + "_id"] = value;
        this.setState({
            product: product,
            dropdown_print: dropdown_print
        });
    };

    validate() {
        var error = "";
        if (this.state.product.brand_id == null) {
            error = "Please select a brand";
        } else if (this.state.product.type_id == null) {
            error = "Please select a type";
        } else if (this.state.product.owner_id == null) {
            error = "Please select a company";
        } else if (this.state.product.name == "") {
            error = "Please enter a name";
        } else if (this.state.product.description == "") {
            error = "Please enter a description";
        }
        if (error == "") {
            return true;
        } else {
            Swal("Error", error, "error");
            return false;
        }
    }

    saveProduct = () => {
        if (this.validate()) {
            var self = this;
            axios.defaults.withCredentials = true;
            if (this.state.mode == "Add") {
                axios.post(API_ROOT + '/products/', self.state.product)
                    .then(function (response) {
                        self.getProductParents();
                        self.setState({
                            modal: !self.state.modal
                        });
                        Swal.fire({
                            title: 'Product Added',
                            text: "Would you like to add SKUs now?",
                            showCancelButton: true,
                            confirmButtonText: 'Yes, add SKUs Now',
                            cancelButtonText: 'Later',
                        }).then((result) => {
                            if (result.value) {
                                self.props.history.push("/product/" + response.data.id);
                            }
                        })
                    })
                    .catch(function (error) {
                        Swal("Error", error.response.data.Message, "error");
                    });
            } else {
                axios.put(API_ROOT + '/products/' + this.state.product_parent_id, self.state.product)
                    .then(function (response) {
                        self.getProductParents();
                        self.setState({
                            modal: !self.state.modal
                        });
                        toast.success("Changes Saved", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    })
                    .catch(function (error) {
                        Swal("Error", error.response.data.Message, "error");
                    });
            }
        }
    };

    deleteProduct = () => {
        var self = this;
        axios.defaults.withCredentials = true;
        Swal.fire({
            title: 'Are you sure?',
            text: "The product and all SKUs will be deleted. This will not affect sales history of products already sold.",
            showCancelButton: true,
            confirmButtonText: 'Yes, delete now',
            cancelButtonText: 'No',
        }).then((result) => {
            axios.delete(API_ROOT + '/products/' + self.state.product_parent_id)
                .then(function (response) {
                    self.getProductParents();
                    self.setState({
                        modal: !self.state.modal
                    });
                    toast.success("Changes Saved", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                })
                .catch(function (error) {
                    Swal("Error", error.response.data.Message, "error");
                });
        });
    };

    openEdit = (key, e) => {
        e.stopPropagation();
        this.toggleModal();
        this.setState({
            product: {
                name: this.state.products[key].name,
                description: this.state.products[key].description,
                owner_id: this.state.products[key].product_owner_id,
                brand_id: this.state.products[key].product_brand_id,
                type_id: this.state.products[key].product_type_id,
            },
            dropdown_print: {
                owner: this.state.list.owner[this.state.products[key].product_owner_id].name,
                brand: this.state.list.brand[this.state.products[key].product_brand_id].name,
                type: this.state.list.type[this.state.products[key].product_type_id].name,
            },
            product_parent_id: this.state.products[key].product_parent_id,
            mode: "Edit"
        });
    };

    addSKU = (key, e) => {
        e.stopPropagation();
        this.props.history.push("/product/" + key);
    };

    openAdd = () => {
        this.toggleModal();
        this.setState({
            product: {
                name: null,
                description: null,
                owner_id: null,
                brand_id: null,
                type_id: null,
            },
            dropdown_print: {
                owner: "",
                brand: "",
                type: "",
            },
            product_parent_id: null,
            mode: "Add"
        });
    };

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    };

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>
                        Products
                    </div>
                    <div className="ml-auto">
                        <Button color="primary" onClick={this.openAdd}><i className="fa fa-plus"></i> Add Product</Button>
                        <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
                            <div className="modal-header h4">{this.state.mode} Product</div>
                            <ModalBody className="pt-0">
                                <Alert color="info"><i className="fas fa-info-circle"></i> A product is the brand/liquid, and not specific to a pack size/SKU - you'll add that
                                    later.</Alert>
                                <Form>
                                    <FormGroup row>
                                        <Label for="name" sm={2}>Name</Label>
                                        <Col sm={10}>
                                            <Input type="text" name="name" id="name" placeholder="Product Name (e.g. Coors Light Lime)" value={this.state.product.name}
                                                   onChange={this.handleChange}/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="description" sm={2}>Description</Label>
                                        <Col sm={10}>
                                            <Input type="textarea" name="description" id="description" placeholder="A crisp, clear beer that is perfectly paired with..."
                                                   value={this.state.product.description} onChange={this.handleChange}/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={2}>Company</Label>
                                        <Col sm={10}>
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle color="secondary" caret size="sm" className="text-left">
                                                    {this.state.dropdown_print.owner}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.list.owner).map(function (owner_id, key) {
                                                        return (
                                                            <DropdownItem key={owner_id}
                                                                          onClick={() => this.changeDropdown(owner_id, "owner")}>{this.state.list.owner[owner_id].name}</DropdownItem>
                                                        )
                                                    }, this)}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                            <Link to="../../lists/owner" className="ml-2 text-muted" title="Add/Edit List"><i class="fas fa-edit"></i></Link>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={2}>Brand</Label>
                                        <Col sm={10}>
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle color="secondary" caret size="sm" className="text-left">
                                                    {this.state.dropdown_print.brand}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.list.brand).map(function (brand_id, key) {
                                                        return (
                                                            <DropdownItem key={brand_id}
                                                                          onClick={() => this.changeDropdown(brand_id, "brand")}>{this.state.list.brand[brand_id].name}</DropdownItem>
                                                        )
                                                    }, this)}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                            <Link to="../../lists/brand" className="ml-2 text-muted" title="Add/Edit List"><i class="fas fa-edit"></i></Link>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={2}>Type</Label>
                                        <Col sm={10}>
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle color="secondary" caret size="sm" className="text-left">
                                                    {this.state.dropdown_print.type}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.list.type).map(function (type_id, key) {
                                                        return (
                                                            <DropdownItem key={type_id}
                                                                          onClick={() => this.changeDropdown(type_id, "type")}>{this.state.list.type[type_id].name}</DropdownItem>
                                                        )
                                                    }, this)}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                            <Link to="../../lists/type" className="ml-2 text-muted" title="Add/Edit List"><i class="fas fa-edit"></i></Link>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" onClick={this.deleteProduct} className="pull-left">Delete</Button>
                                <Button color="primary" onClick={this.saveProduct}>{this.state.mode} Product</Button>
                                <Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                </div>
                <div className="mb-3 form-inline d-none">
                    <InputGroup>
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color="info" style={{fontSize: '13px', width: 'auto'}}>
                                Type: <b>{this.state.type_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeFilter("type", "all")}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.list.type).map((key, i) =>
                                    <DropdownItem key={key} onClick={() => this.changeFilter("type", key)}>{this.state.list.type[key].name}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                    <InputGroup>
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color="info" style={{fontSize: '13px', width: 'auto'}}>
                                Brand: <b>{this.state.brand_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeFilter("brand", null)}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.list.brand).map((key, i) =>
                                    <DropdownItem key={key} onClick={() => this.changeFilter("brand", key)}>{this.state.list.brand[key].name}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                    <InputGroup>
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color="info" style={{fontSize: '13px', width: 'auto'}}>
                                Company: <b>{this.state.co_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeFilter("owner", null)}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.list.owner).map((key, i) =>
                                    <DropdownItem key={key} onClick={() => this.changeFilter("owner", key)}>{this.state.list.owner[key].name}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                    <InputGroup>
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color="info" style={{fontSize: '13px', width: 'auto'}}>
                                Format: <b>{this.state.factor_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeFilter("factor", null)}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.list.factor).map((key, i) =>
                                    <DropdownItem key={key} onClick={() => this.changeFilter("factor", key)}>{this.state.list.factor[key].name}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div className={(this.state.loading ? "invisible" : "")}>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className={(!this.state.loading ? "table table-hover table-pointer text-left" : "d-none")}>
                                    <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th className="d-md-table-cell">Type</th>
                                        <th className="d-md-table-cell">Brand</th>
                                        <th className="d-lg-table-cell">Company</th>
                                        <th className="d-lg-table-cell">Format(s)</th>
                                        <th className="d-lg-table-cell">Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.products_sort.map(function (product, key) {
                                        var product_parent_id = product.id;
                                        var formats = "";
                                        var self = this;
                                        Object.keys(self.state.products[product_parent_id].products).map(function (product_id) {
                                            var product = self.state.products[product_parent_id].products[product_id];
                                            formats += '<span class="badge badge-pill badge-secondary">' + self.state.list.factor[product.product_factor_id].name + '</span> ';
                                        });
                                        return (
                                            <React.Fragment key={key}>
                                                <tr onClick={self.toggleProductParent.bind(self, product_parent_id)}>
                                                    <td className="d-lg-table-cell">{self.state.products[product_parent_id].name}</td>
                                                    <td className="d-md-table-cell">{self.state.products[product_parent_id].type_name}</td>
                                                    <td className="d-md-table-cell">{self.state.products[product_parent_id].brand_name}</td>
                                                    <td className="d-md-table-cell">{self.state.products[product_parent_id].owner_name}</td>
                                                    <td className="d-md-table-cell">
                                                        <div dangerouslySetInnerHTML={{__html: formats}}/>
                                                    </td>
                                                    <td className="d-md-table-cell">
                                                        <div className="btn btn-xs btn-primary" title="Edit/Delete Product" onClick={self.openEdit.bind(self, product_parent_id)}><i
                                                            className="far fa-edit"></i></div>
                                                        <div className="btn btn-xs btn-primary ml-1" title="Add SKU" onClick={self.addSKU.bind(self, product_parent_id)}><i
                                                            className="fas fa-plus"></i> SKU
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className={(self.state.products[product_parent_id].visible ? "" : "d-none")}>
                                                    <td colSpan={6} className="border-top-0 p-0 ml-1 mr-1">
                                                        <ProductRows products={self.state.products[product_parent_id].products}
                                                                     parent_id={self.state.products[product_parent_id].product_parent_id} history={self.props.history}
                                                                     factor={self.state.list.factor}/>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    }, this)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer/>
            </ContentWrapper>
        );
    }
}

export default (ProductsOverview);


class ProductRows extends React.Component {
    constructor(props) {
        super(props);
    }

    goToProduct = (product_parent_id, product_id) => {
        this.props.history.push("/product/" + this.props.parent_id + '/' + product_id);
    };

    render() {
        var self = this;
        return (
            <table className="table table-sm">
                <thead className="thead-light">
                <tr>
                    <th>SKU Name</th>
                    <th>Format</th>
                    <th>Price</th>
                    <th>Deposit</th>
                    <th>Max Qty.</th>
                </tr>
                </thead>
                <tbody>
                {Object.keys(this.props.products).map(function (product_id, key) {
                    return (
                        <tr key={key} onClick={self.goToProduct.bind(self, self.props.products[product_id].product_parent_id, product_id)} title="View / Edit Product">
                            <td>{self.props.products[product_id].name}</td>
                            <td>{self.props.factor[self.props.products[product_id].product_factor_id].name}</td>
                            <td>{self.props.products[product_id].pricing[1].price == null ? "Not Set" : "$" + self.props.products[product_id].pricing[1].price.toFixed(2)}</td>
                            <td>{(self.props.products[product_id].deposit != null && !isNaN(self.props.products[product_id].deposit) ? "$" + self.props.products[product_id].deposit.toFixed(2) : "NA")}</td>
                            <td>{self.props.products[product_id].available}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        );
    }
}